import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as PlayCircleIcon } from "../../../../../../../../assets/icons/icon-play-circle.svg";
import FilsIllustraion from "../../../../../../../../assets/images/files-illustraion.png";
import { useAppSelector } from "../../../../../../../../redux/hooks";
import { getTestsList } from "../../../../../../../../redux/pages/courseSlice";

const TestList = () => {

   const [testData, setTestData] = useState<any>();

   const dispatch = useDispatch<any>();
   const subjectInfo = useAppSelector((state) => state.course.newSubject);
   const subjectTestsList = useAppSelector((state) => state.course.subjectTestsList);

   useEffect(() => {
      if (subjectInfo && subjectInfo?.id) {
         const body = {
            subject_id: subjectInfo?.id,
         };
         dispatch(getTestsList(body));
      }
   }, [subjectInfo]);

   useEffect(() => {
      setTestData(subjectTestsList);
   }, [subjectTestsList]);


   const testTemplate = (
      <div className="pe-2">
         {testData && testData?.length ?
            <>
               {testData?.map((item: any, key: any) => (
                  <button key={key} className="video-card mb-2">
                     <div className="d-flex  align-items-center">
                        <p className="title">{item?.name}</p>
                     </div>
                  </button>
               ))}
            </>
            :
            <div className="d-flex align-items-center justify-content-center empty-msg-wrap">
               <div className="empty-msg">
                  <div className="files-illustraion mb-3">
                     <img
                        src={FilsIllustraion}
                        className="img-fluid"
                        alt="empty illustraion"
                     />
                  </div>
                  <p className="description">
                     No Tests added
                  </p>
               </div>
            </div>}
      </div>
   );


   return (
      <div className="card h-100">
         <div className="card-title-wrapper">
            <h3 className="card-title">{subjectInfo?.name}</h3>
            <h4 className="card-subtitle mb-4">Tests</h4>
            <div className="line"></div>
         </div>
         {testTemplate}
      </div>
   );
};

export default TestList;