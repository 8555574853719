import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";

export interface profileInterface {
  isLoading: boolean;
  errors: any;
  profileStore:any;
  profileDataUpdate:any;

}

const initialState: profileInterface = {
  isLoading: false,
  errors: null,
  profileStore:[] ,
  profileDataUpdate:[],

}

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setLoading: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = payload;
    },
    setProfileStore: (state, { payload }: PayloadAction<any>) => {
      state.profileStore = payload;
    },
    setProfileImage: (state, { payload }: PayloadAction<any>) => {
      state.profileDataUpdate= payload;
   },
  }
})

export const getProfileData = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
     let URL = "/profile/index";
     const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
     if (response.data) {
      console.log(response?.data,"profile dat")
        if (response.data.status && response.data.status === "success") {
          dispatch( setProfileStore(response.data));
          dispatch(setLoading(false));    
        }
     }
  } catch (error: any) {
     dispatch(setLoading(false));
     dispatch(setErrors(error?.response));
  }
};

export const profileDataUpdate = (body: {image:any}): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
     let URL = "/profile/image/store";
     const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
     if (response.data) {
        if (response.data.status && response.data.status === "success") {
           dispatch(setLoading(false));
           showNotification({
              message: "Profile data updated.",
              theme: "light",
              type: "success",
           });
           dispatch( setProfileImage(body));
        }
     }
  } catch (error: any) {
     dispatch(setLoading(false));
     dispatch(setErrors(error?.response));
  }
};

export const onUpdatePassword = (body: { password: string; new_password: string; confirm_password: string }): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let URL = "/profile/change/password";
      const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
      if (response.data) {
        if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
        showNotification({
          message: "Profile password updated.",
          theme: "light",
          type: "success",
        });
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrors(error?.response));
    }
  };

export const {
  setLoading,
  setErrors,
  setProfileStore,
  setProfileImage ,
} = profileSlice.actions

export default profileSlice.reducer;
