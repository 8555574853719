import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import Lecture from "./lecture/lecture";
import ChapterTest from "./test/chapterTest";
import LectureList from "./lecture/lectureList";
import TestList from "./test/testList";
import SubjectTest from "./test/subjectTest";

import { useAppSelector } from "../../../../../../../redux/hooks";
import {
  getChaptersList,
  getTestsList,
  setSelectedContentInfo,
  setTutorialDetails,
} from "../../../../../../../redux/pages/courseSlice";

const Content = () => {
  const [selectedItem, setSelectedItem] = useState<string>("lecture");
  const [isSeries, setIsSeries] = useState<boolean>(false);
  const [selectedChapter, setSelectedChapter] = useState<any>("");
  const [chapterName, setChapterName] = useState<any>("");
  const [selectedTest, setSelectedTest] = useState<any>("");
  const [selectedChapterTest, setSelectedChapterTest] = useState<any>("");
  const [isQuestionMode, setIsQuestionMode] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const subjectInfo = useAppSelector((state) => state.course.newSubject);
  const chaptersList = useAppSelector((state) => state.course.chaptersList);
  const subjectTestsList = useAppSelector((state) => state.course.subjectTestsList);

  useEffect(() => {
    if (subjectInfo && subjectInfo?.id) {
      const body = {
        subject_id: subjectInfo?.id,
      };
      dispatch(getTestsList(body));
      dispatch(getChaptersList(subjectInfo?.id));
      dispatch(setSelectedContentInfo(null));
      dispatch(setTutorialDetails(null));
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedChapter) {
      setChapterName(
        chaptersList?.find((item: any) => item?.id === selectedChapter)?.name
      );
      setIsQuestionMode(false);
    }
  }, [selectedChapter]);

  return (
    <div className="row gap-4 gap-xl-0">
      <div className="col-xl-7">
        <div className="card h-100">
          <div className="card-title-wrapper">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3 className="card-title">
                {isSeries ? "Create Test" : "Content Upload"}
              </h3>
              <div
                className={`d-flex justify-content-between align-items-center mb-3 form-switch ${
                  subjectTestsList && subjectTestsList?.length ? "" : "disabled"
                }`}
              >
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckChecked"
                >
                  Series
                </label>
                <input
                  className="form-check-input"
                  style={{ margin: "5px" }}
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={isSeries}
                  onChange={(e) => setIsSeries(e.target.checked)}
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-xl-7">
                <div className="form-group mb-3">
                  {isSeries ? (
                    <select
                      id="category_option"
                      className="form-select form-group-dropdown mt-0"
                      value={selectedTest}
                      onChange={(e) => setSelectedTest(e?.target?.value)}
                    >
                      <option value="" disabled>
                        {" "}
                        Select Test{" "}
                      </option>
                      {subjectTestsList?.map((data: any, index: number) => (
                        <option
                          key={index}
                          value={data.id}
                          className="form-group-option"
                        >
                          {data.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      id="category_option"
                      className="form-select form-group-dropdown mt-0"
                      value={selectedChapter}
                      onChange={(e) => setSelectedChapter(e?.target?.value)}
                    >
                      <option value="" disabled>
                        {" "}
                        Select Chapter{" "}
                      </option>
                      {chaptersList?.map((data: any, index: number) => (
                        <option
                          key={index}
                          value={data.id}
                          className="form-group-option"
                        >
                          {data.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>

              {!isSeries ? (
                <div className="col-xl-5">
                  <div className="d-flex align-items-center gap-4 mt-2 mb-3">
                    <div className="form-radio-btn mb-0">
                      <input
                        type="radio"
                        name="radioDefault"
                        id="radio1"
                        checked={selectedItem === "lecture"}
                        onChange={() => setSelectedItem("lecture")}
                      />
                      <label htmlFor="radio1">Lecture</label>
                    </div>
                    <div className="form-radio-btn mb-0">
                      <input
                        type="radio"
                        name="radioDefault"
                        id="radio2"
                        checked={selectedItem === "test"}
                        onChange={() => setSelectedItem("test")}
                      />
                      <label htmlFor="radio2">Test</label>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="line"></div>
          </div>
          {isSeries ? (
            <SubjectTest selectedTest={selectedTest} />
          ) : (
            <>
              {selectedItem === "lecture" ? (
                <Lecture selectedChapter={selectedChapter} />
              ) : (
                <ChapterTest
                  selectedChapter={selectedChapter}
                  isQuestionView={isQuestionMode}
                  testId={selectedChapterTest}
                  setIsQuestionMode={setIsQuestionMode}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="col-xl-5">
        {isSeries ? (
          <TestList />
        ) : (
          <LectureList
            selectedItem={selectedItem}
            selectedChapter={selectedChapter}
            chapterName={chapterName}
            setSelectedTest={setSelectedChapterTest}
            setIsQuestionMode={setIsQuestionMode}
          />
        )}
      </div>
    </div>
  );
};

export default Content;
