
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../../category/Category.scss"

//components
import Search from "../../../../common/components/Search/Search";
import AdvancedSort from "../../../../common/components/AdvancedSort/AdvancedSort";
import EditsubCategoryModal from "./edit-Subcategory/Edit-SubCategory";
import CreatesubCategoryModal from "./create-Subcategory/Create-SubCategory";
import DeleteModal from "../../../../common/components/Modal/DeleteModal/DeleteModal";
//icons
import { ReactComponent as AddCirclePlusIcon } from "../../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/icon-trash.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/icon-edit.svg"
import { ReactComponent as BookmarkIcon } from "../../../../assets/icons/icon-bookmark.svg";
//redux
import { useAppSelector } from "../../../../redux/hooks";
import { deleteSubProgram, getAllSubProgramsList, setSubProgramsList } from "../../../../redux/pages/categorySlice";

const ManageSubCategory = () => {

  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [subProgramData, setSubProgramData] = useState<any>([]);
  const [supProgramId, setSubProgramId] = useState(null);
  const [subProgramDetails, setSubProgramDetails] = useState<any>([]);
  const [sortedSubProgramList, setSortedSubProgramList] = useState<any>([]);
  //serach
  const [searchTerm, setSearchTerm] = useState<string>("");
  //sorting
  const [sortConfig, setSortConfig] = useState<any>({ sortOrder: "", sortBy: "", sort: "" });
  //redux
  const dispatch = useDispatch<any>();
  const subprogramInfo = useAppSelector((state) => state.programs.subProgramsList);


  useEffect(() => {
    dispatch(getAllSubProgramsList({}));
  }, [])

  useEffect(() => {
    if (subprogramInfo) {
      setSubProgramData(subprogramInfo);
      dispatch(setSubProgramsList(""));
    }
  }, [subprogramInfo]);

  const handleDelete = (id: any) => {
    setShowDeleteModal(true);
    setSubProgramId(id);
  };

  const handleEditClick = (data: any) => {
    setShowEditModal(true);
    setSubProgramDetails(data);
  };

  const onDeleteSubProgram = () => {
    if (supProgramId) {
      const data = { id: supProgramId };
      dispatch(deleteSubProgram(data, setShowDeleteModal, setSubProgramId));
    }
  };

  const onCancelDeleteAction = () => {
    setSubProgramId(null);
  };

  const onSearch = useCallback((keyword: string) => {
    if (keyword) {
      dispatch(getAllSubProgramsList({ search: keyword, sort: sortConfig?.sort }));
    }
  }, [sortConfig]);

  const onSort = useCallback((config: any) => {
    if (config) {
      setSortConfig(config);
      dispatch(getAllSubProgramsList({ search: searchTerm, sort: config?.sort }));
    }
  }, [searchTerm]);

  return (
    <>
      <div className="manage-category-wrapper h-100">
        {/* title section */}
        <div className="d-flex justify-content-between flex-wrap title-wrapper mb-3 mx-4">
          <button className="back-button" onClick={() => navigate("/courses")}>
            <ArrowLeftIcon />
          </button>
          <div>
            <h2 className="title">Manage Sub-Category</h2>
            <p className="desc mb-2">Create new Sub-Category and manage it here.</p>
          </div>
          <div
            className="d-flex al gap-3"
            onClick={() => setShowCreateModal(true)}
          >
            <a className="btn btn-primary">
              <span className="me-1">
                <AddCirclePlusIcon />
              </span>
              Create sub Category
            </a>
          </div>
        </div>

        <div className="card ms-2 me-4">
          <h3 className="card-title">All sub Category</h3>
          {/* control section */}
          <div className="control-section-wrapper ">
            <div className="d-flex justify-content-between flex-wrap gap-3  control-wrapper py-3 me-4">
            <Search keyword={searchTerm} setKeyword={setSearchTerm} onSearch={onSearch} placeholder="Search Sub-Category" />
              <AdvancedSort sortOrder={sortConfig?.sortOrder} sortBy={sortConfig?.sortBy} onSort={onSort} />
            </div>
          </div>

          {/* table section */}
          <div className="table-wrapper">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Sub-Category</th>
                  <th scope="col" className="text-center">
                    Price
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {(sortedSubProgramList.length > 0 ? sortedSubProgramList : subProgramData)?.map((data: any, key: any) => (
                  <tr key={key}>
                    <td className="">
                      <div className="d-flex">
                        <span className="me-2">
                          <BookmarkIcon />
                        </span>
                        {data.name}
                      </div>
                    </td>
                    <td max-width="20%" className="text-center">
                      <p className="price">$ {data.price}</p>
                    </td>
                    <td width="10%" className="text-end">
                      <div className="dropdown more-dropdown">
                        <button
                          className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                          // type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <DotsVerticalIcon />
                        </button>

                        <ul className="dropdown-menu more-dropdown-menu">
                          <li onClick={() => handleEditClick(data)}>
                            <a className="dropdown-item">
                              <span className="me-2">
                                <EditIcon />
                              </span>
                              Edit
                            </a>
                          </li>
                          <li>
                            <button
                              className="dropdown-item text-danger"
                              onClick={() => handleDelete(data.id)}
                            >
                              <span className="me-2">
                                <TrashIcon />
                              </span>
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onDeleteSubProgram}
          onCancel={onCancelDeleteAction}
          message={`The category ${subProgramData?.find((item: any) => item.id === supProgramId)?.name
            } will be permanently deleted.`}
        />
      )}
      {showCreateModal && (
        <CreatesubCategoryModal showModal={setShowCreateModal} />
      )}
      {showEditModal && (
        <EditsubCategoryModal
          subprogramInfo={subProgramDetails}
          showModal={setShowEditModal}
        />
      )}
    </>
  );
};

export default ManageSubCategory;
