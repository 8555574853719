import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import "./editModal-styles.scss";

import { ReactComponent as EyeIcon } from "../../../../../assets/icons/icon-eye.svg";
import { ReactComponent as EyeSlashedIcon } from "../../../../../assets/icons/icon-eye-slash.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/icon-edit.svg";

import { getRoleList, updateUser } from "../../../../../redux/pages/userSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../redux/hooks";
import { editMemberSchema } from "../../../../../validations/userValidations";

interface memberModalProps {
  userData: any;
  setShowEditMember: (value: boolean) => void;
  setUserData: (data: any) => void;
}
const EditMemberModal: React.FC<memberModalProps> = (props) => {
  const {
    userData = "",
    setShowEditMember = () => { },
    setUserData = () => {}
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(editMemberSchema),
  });

  const imageRef = useRef<any>()
  const createUserForm = useRef<any>();

  const [file, setFile] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>(null);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [userRoleData, setUserRoleData] = useState<any>([]);
  const [password,setPassword] = useState<any>("")

  const dispatch = useDispatch<any>();
  const rolesInfo = useAppSelector((state) => state.user?.roleList);

  useEffect(() => {
    dispatch(getRoleList());
  }, []);

  useEffect(() => {
    if(userData) {
      // setValue("role", userData?.role[0]?.id);
      setValue("name", userData?.name);
      setValue("email", userData?.email);
      setValue("dob", userData?.dob);
      setValue("phone", userData?.phone);
      setProfileImage(userData?.profile_image);
      clearErrors(["name", "confirm_password", "dob", "email", "password", "phone", "role"]);
    }
  }, [userData]);


  useEffect(() => {
    setUserRoleData(rolesInfo);
  }, [rolesInfo]);

  useEffect(() => {
    if (errors) {
      if (errors?.name || errors?.role || errors?.dob || errors?.phone) {
        document.getElementById("pills-basic-profile-tab")?.click();
      }
    }
  }, [errors]);

  const handleFileChange = (e: any) => {
    const fileItem = e?.target?.files[0];
    if (fileItem) {
      var fileName = fileItem?.name,
        idxDot = fileName.lastIndexOf(".") + 1,
        extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        setValue("profile_image", fileItem);
        setFile(URL.createObjectURL(e.target.files[0]));
      } else {
        // setFileErrorMessage("Only images of type jpg, jpeg, png are allowed!");
      }
    }
  }

  const handleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };
  const handleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };


  const onEditUserSubmit = (data: any) => {
    if(data) {
      let formData = new FormData();
      formData.append("id", userData?.id);
      formData.append("role", data?.role);
      formData.append("name", data?.name);
      formData.append("email", data?.email);
      formData.append("phone", data?.phone);
      formData.append("dob", moment(data?.dob).format('YYYY-MM-DD'));
      if(data?.password !== "" && data?.confirm_password !== "") {
        formData.append("password", data?.password);
        formData.append("confirm_password", data?.confirm_password);
      }
      if(file) {
        formData.append("profile_image", data?.profile_image);
      };
      dispatch(updateUser(formData, setShowEditMember));
    }
  }

  const onCancel = () => {
    setShowEditMember(false);
    setUserData(null);
  };

  return (
    <div
      className="as-modal add-member-modal"
      id="add-member-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add new member</h4>
              <button
                className="close-modal-btn"
              // onClick={() => showModal(false)}
              >
                {/* <CircleCloseIcon /> */}
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex gap-4">
                <div className="user-image-upload mt-5">
                  <div className="user-img-wrapper">
                    <img
                      src={file ? file : profileImage ? profileImage : "https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZGF0YSUyMHNjaWVuY2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"}
                      alt=""
                    />
                    <input ref={imageRef} onChange={handleFileChange} style={{ display: "none" }} type="file" accept="image/png, image/jpeg" />
                    <button className="btn btn-primary edit-btn" onClick={() => imageRef.current.click()}>
                      <span className="me-1" >
                        <EditIcon />
                      </span>
                      Edit
                    </button>
                  </div>
                </div>
                <div className="tab-section">
                  <form onSubmit={handleSubmit(onEditUserSubmit)} ref={createUserForm}>
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-basic-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-basic-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Basic Profile
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-login-credential-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-login-credential"
                          type="button"
                          role="tab"
                          aria-controls="pills-login-credential"
                          aria-selected="false"
                        >
                          Login Credential
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-basic-profile"
                        role="tabpanel"
                        aria-labelledby="pills-basic-profile-tab"
                        tabIndex={0}
                      >
                        <div className="form-wrapper">

                          <div className="form-group">
                            <h5 className="form-group-label">Role</h5>
                            <select
                              className={`form-group-control ${errors.role ? "error-border" : ""}`}
                              {...register("role")}
                              name="role"
                              disabled={!userRoleData?.length}
                            >
                              <option value="" disabled>Select a role</option>
                              {userRoleData?.map((role: any, key: any) => (
                                <option key={key} value={role.id} className="form-group-option active selected" >
                                  {role?.name}
                                </option>
                              ))}
                            </select>
                            <p className="validation-text">{errors.role?.message}</p>
                          </div>
                          <div className="form-group">
                            <h5 className="form-group-label">Name</h5>
                            <input
                              type="text"
                              className={`form-group-control ${errors.name ? "error-border" : ""}`}
                              placeholder="User Name"
                              {...register("name")}
                              name="name"
                            />
                            <p className="validation-text">{errors.name?.message}</p>
                          </div>

                          <div className="form-group">
                            <h5 className="form-group-label">DOB</h5>
                            <input
                              type="date"
                              className={`form-group-control ${errors.dob ? "error-border" : ""}`}
                              {...register("dob")}
                              name="dob"
                              placeholder="DOB"
                            />
                            <p className="validation-text">{errors.dob?.message}</p>
                          </div>
                          <div className="form-group">
                            <h5 className="form-group-label">Phone number</h5>
                            <input
                              type="text"
                              className={`form-group-control ${errors.phone ? "error-border" : ""}`}
                              {...register("phone")}
                              name="phone"
                              placeholder="Phone Number"
                            />
                            <p className="validation-text">{errors.phone?.message}</p>
                          </div>
                          <div className="d-flex btn-wrapper justify-content-between mt-3">
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-danger"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-primary close-modal-btn"
                              onClick={() => document.getElementById("pills-login-credential-tab")?.click()}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-login-credential"
                        role="tabpanel"
                        aria-labelledby="pills-login-credential"
                        tabIndex={0}
                      >
                        <div className="form-wrapper">
                          <div className="form-group">
                            <h5 className="form-group-label">Email</h5>
                            <input
                              type="email"
                              className={`form-group-control ${errors.email ? "error-border" : ""}`}
                              placeholder="Mail id"
                              {...register("email")}
                              name="email"
                            />
                            <p className="validation-text">{errors.email?.message}</p>
                          </div>
                          <div className="form-group">
                            <div className="d-flex">
                              <h5 className="form-group-label">Password</h5>
                              {/* <span className="ms-2 password-info-icon">
                                <button
                                  type="button"
                                  className="tooltip-btn"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-custom-class="custom-tooltip"
                                  data-bs-title="This top tooltip is themed via CSS variables."
                                >
                                  <InfoCircleIcon />
                                </button>
                                <div className="tooltip-dropdown">
                                  <div className="tooltip-content-wrap">
                                    <h6 className="tooltip-title">
                                      Password Rule
                                    </h6>
                                    <p className="tooltip-hints">
                                      Password must be 8 - 12 symbols long
                                    </p>
                                    <p className="tooltip-hints">
                                      Must be alpha-numeric
                                    </p>
                                    <p className="tooltip-hints">
                                      Must be contain special symbols
                                    </p>
                                    <div className="triangle-shape">
                                      <div className="triangle-with-shadow"></div>
                                    </div>
                                  </div>
                                </div>
                              </span> */}
                            </div>
                            <div className="icon-form-control">
                              <input
                                type={newPasswordVisible ? "text" : "password"}
                                className={`form-group-control ${errors.password ? "error-border" : ""}`}
                                placeholder="Password"
                                {...register("password")}
                                name="password"
                              />
                              <div className="end-icon">
                                <a onClick={handleNewPasswordVisibility} >
                                  {newPasswordVisible ? (
                                    <EyeIcon />
                                  ) : (
                                    <EyeSlashedIcon />
                                  )}
                                </a>
                              </div>
                            </div>
                            <p className="validation-text">{errors.password?.message}</p>
                          </div>
                          <div className="form-group">
                            <h5 className="form-group-label">
                              Confirm Password
                            </h5>
                            <div className="icon-form-control">
                              <input
                                type={
                                  confirmPasswordVisible ? "text" : "password"
                                }
                                className={`form-group-control ${errors.confirm_password ? "error-border" : ""}`}
                                placeholder="Confirm Password"
                                {...register("confirm_password")}
                                name="confirm_password"
                              />
                              <div className="end-icon">
                                <a onClick={handleConfirmPasswordVisibility}  >
                                  {confirmPasswordVisible ? (
                                    <EyeIcon />
                                  ) : (
                                    <EyeSlashedIcon />
                                  )}
                                </a>
                              </div>
                            </div>
                            <p className="validation-text">{errors.confirm_password?.message}</p>
                          </div>

                          <div className="d-flex btn-wrapper justify-content-between mt-3">
                            <button
                              onClick={onCancel}
                              type="button"
                              className="btn btn-sm btn-outline-danger"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary close-modal-btn"
                              onClick={() => createUserForm?.current?.requestSubmit()}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMemberModal;
