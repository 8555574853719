import React, { useEffect, useState } from "react";
import "./settings.scss";

// icons
import { ReactComponent as EditIcon } from "../../../assets/icons/icon-edit-outline-blue.svg";

import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";
import { ReactComponent as GreenArrowIcon } from "../../../assets/icons/icon-arrow-up-green.svg";
import { ReactComponent as RedArrowIcon } from "../../../assets/icons/icon-arrow-up-red.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/icon-chevron-down.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/icon-mail.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/icon-lock.svg";
import { ReactComponent as EyeIcon } from "../../../assets/icons/icon-eye.svg";
import { ReactComponent as EyeSlashedIcon } from "../../../assets/icons/icon-eye-slash.svg";
import PasswordSuccessModal from "../../../common/components/Modal/PasswordSuccessModal/PasswordSuccessModal";
import ChangePassword from "./ChangePassword/ChangePassword";

const Settings = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const handleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <>
      <div className="settings-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between align-items-center flex-wrap title-wrapper mb-1 mx-4">
          {/* <button className="back-button">
            <ArrowLeftIcon />
          </button> */}
          <div>
            <h2 className="title">Settings</h2>
            <p className="desc mb-2">You can change app settings here</p>
          </div>
        </div>

        {/* tab section */}
        <div className="tab-section mx-4">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-offers-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-offers"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                General
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-coupons-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-coupons"
                type="button"
                role="tab"
                aria-controls="pills-coupons"
                aria-selected="false"
              >
                Settings
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-offers"
              role="tabpanel"
              aria-labelledby="pills-offers-tab"
            >
              <div className="">
                <div className="card settings-card">
                  <h4 className="title mb-1">Profile</h4>
                  <p className="desc">User Profile</p>
                  <hr className="line" />
                  <div className="card-body-content">
                    <div className="d-flex body-wrapper">
                      <div>
                        <div className="avatar mb-3">
                          <img
                            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60̉"
                            alt=""
                          />
                        </div>
                        <button className="btn btn-outline-primary edit-profile-btn">
                          Edit Profile pic
                        </button>
                      </div>
                      <div className="content">
                        <div className="row mb-3">
                          <div className="col-3">
                            <p className="text fw-bold">Name</p>
                          </div>
                          <div className="col-1">
                            <p className="text">:</p>
                          </div>
                          <div className="col-8">
                            <p className="text">John Doe</p>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-3">
                            <p className="text fw-bold">Email</p>
                          </div>
                          <div className="col-1">
                            <p className="text">:</p>
                          </div>
                          <div className="col-8">
                            <p className="text">johndoe12@email.com</p>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-3">
                            <p className="text fw-bold">Ph No</p>
                          </div>
                          <div className="col-1">
                            <p className="text">:</p>
                          </div>
                          <div className="col-8">
                            <p className="text">+91 8337287373</p>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-3">
                            <p className="text fw-bold">DOB</p>
                          </div>
                          <div className="col-1">
                            <p className="text">:</p>
                          </div>
                          <div className="col-8">
                            <p className="text">09/10/1999</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-3">
                            <p className="text fw-bold">Role</p>
                          </div>
                          <div className="col-1">
                            <p className="text">:</p>
                          </div>
                          <div className="col-8">
                            <p className="text">Super Admin</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-coupons"
              role="tabpanel"
              aria-labelledby="pills-coupons"
            >
              <ChangePassword/>
            
            </div>
          </div>
        </div>
      </div>
      {/* <PasswordSuccessModal /> */}
    </>
  );
};

export default Settings;
