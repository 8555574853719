import "./login.scss";
import LoginForm from "./components/LoginForm";

const Login = () => {
  return (
    <div className="as-login-wrapper">
      <div className="row h-100">
        <div className="col-md-6">
          <div className="as-login-left-section h-100">
            <a className="as-logo">
              Assurance
            </a>
            <div className="as-left-wrapper h-100">
              <LoginForm />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="as-login-right-section">
            <div className="as-blur-bg">
              <h4 className="as-quotes-text">
                “ Empower minds, ignite knowledge. Step into the realm of
                limitless learning”.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
