import React, { useState } from "react";
import "./overview.scss";

// icons
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";
import { ReactComponent as GreenArrowIcon } from "../../../assets/icons/icon-arrow-up-green.svg";
import { ReactComponent as RedArrowIcon } from "../../../assets/icons/icon-arrow-up-red.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/icon-chevron-down.svg";

const Overview = () => {
  return (
    <>
      <div className="overview-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between align-items-center flex-wrap title-wrapper mb-1 mx-4">
          {/* <button className="back-button">
            <ArrowLeftIcon />
          </button> */}
          <div>
            <h2 className="title">Overview</h2>
            <p className="desc mb-2">Statistics of Assurance</p>
          </div>
        </div>

        <div className="">
          <div className="details-wrap mx-4">
            <div className="row g-4">
              <div className="col-md-6 col-lg-4">
                <div className="card dashboard-card">
                  <h4 className="title">Total Revenue</h4>
                  <hr className="line" />
                  <div className="count-wrap">
                    <h3 className="count">$230000.00</h3>
                    <div className="card-badge green-badge">
                      <span className="rate">+7.5</span>
                      <span className="icon">
                        <GreenArrowIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card dashboard-card">
                  <h4 className="title">Total Students</h4>
                  <hr className="line" />
                  <div className="count-wrap">
                    <h3 className="count">$230000</h3>
                    {/* <div className="card-badge">
                      <span className="rate">+7.5</span>
                      <span className="icon">
                        <GreenArrowIcon />
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card dashboard-card">
                  <h4 className="title">
                    New Students
                    <span className="inner-title">(This Month)</span>
                  </h4>
                  <hr className="line" />
                  <div className="count-wrap">
                    <h3 className="count">$230000</h3>
                    <div className="card-badge red-badge">
                      <span className="rate">+7.5</span>
                      {/* add "rotate-180" class to icon class to flip icon upside down */}
                      <span className="icon ">
                        <RedArrowIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card dashboard-card">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="title">Registration Statistics</h4>
                    <div className="dropdown sort-dropdown">
                      <button
                        className="control-btn dropdown-toggle"
                        // type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="icon me-1">
                          <ChevronDownIcon />
                        </span>
                        This Month
                      </button>

                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a className="dropdown-item">
                            This Month
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item">
                            Last Month
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item">
                            Last 3 Months
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item">
                            Last 6 Months
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr className="line" />
                  <div className="graph">
                    <p>Graph goes here</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
