import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import "./modal-styles.scss";
import * as yup from "yup";

import { ReactComponent as EditIcon } from "../../../../../assets/icons/icon-edit.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as TrashIcon } from "../../../../../assets/icons/icon-trash.svg";

import DeleteModal from "../../../../../common/components/Modal/DeleteModal/DeleteModal";
import { showNotification } from "../../../../../common/components/Toaster/Toast";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  createRole,
  getPermissionList,
  getRoleList,
  getUserRoleInfo,
  setUserRoleInfo,
  userRoleDelete,
  userRoleUpdate,
} from "../../../../../redux/pages/userSlice";

interface manageRoleProps {
  setShowManageRole: (value: boolean) => void;
}

const ManageRoleModal: React.FC<manageRoleProps> = (props) => {
  const { setShowManageRole = () => {} } = props;

  const manageRoleSchema = yup.object().shape({
    name: yup.string().trim().required("Name is required"),
    description: yup.string().trim().required("Description is required"),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(manageRoleSchema),
  });

  const roleFormRef = useRef<any>();
  const dropdownRef = useRef<any>(null);

  const [roleFlag, setRoleFlag] = useState<string>("add");
  const [userRoleData, setUserRoleData] = useState<any>([]);
  const [permissionsList, setPermissionsList] = useState<any>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<any>("");
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [roleValidation, setRoleValidation] = useState<string>("");
  const [showMenuDropdown, setShowMenuDropdown] = useState(null);

  //redux
  const dispatch = useDispatch<any>();
  const permissionsInfo = useAppSelector((state) => state.user?.permissionList);
  const rolesInfo = useAppSelector((state) => state.user?.roleList);
  const userRoleInfo = useAppSelector((state) => state.user?.userRoleInfo);
  
  useEffect(() => {
    dispatch(getPermissionList());
    dispatch(getRoleList());
  }, []);

  useEffect(() => {
    setUserRoleData(rolesInfo);
  }, [rolesInfo]);

  useEffect(() => {
    if (permissionsInfo && permissionsInfo?.length) {
      const tempPermissions = permissionsInfo.map((item: any) => {
        const updatedData = item?.data?.map((role: any) => ({
          ...role,
          has_role: false,
        }));
        return { ...item, data: updatedData };
      });
      setPermissionsList([...tempPermissions]);
    }
  }, [permissionsInfo]);

  useEffect(() => {
    if (roleFlag === "edit" && userRoleInfo) {
      if (userRoleInfo?.permissions && userRoleInfo?.permissions?.length) {
        setPermissionsList([...userRoleInfo?.permissions]);
      }
      setValue("name", userRoleInfo?.role?.name);
      setValue("description", userRoleInfo?.role?.description);
      clearErrors(["name", "description"]);
    }
  }, [roleFlag, userRoleInfo]);

  useEffect(() => {
    if (roleFlag === "add") {
      dispatch(getPermissionList());
    }
  }, [roleFlag]);

  const handleCheckboxChange = (itemId: any) => {
    const newData = permissionsList?.map((item: any) => ({
      ...item,
      data: item?.data?.map((permission: any) =>
        permission.id === itemId
          ? { ...permission, has_role: !permission.has_role }
          : permission
      ),
    }));
    setPermissionsList(newData);
  };

  const handleNextAction = () => {
    document.getElementById("pills-permissions-tab")?.click();
  };

  const handleRoleSave = () => {
    if (watch("name") === "" || watch("description") === "") {
      document.getElementById("pills-add-new-role-tab")?.click();
      roleFormRef?.current?.requestSubmit();
    } else if (
      !permissionsList?.some((item: any) =>
        item.data.some((role: any) => role.has_role === true)
      )
    ) {
      document.getElementById("pills-permissions-tab")?.click();
      showNotification({
        message: "Atleast one permission should be selected",
        theme: "coloured",
        type: "error",
      });
    } else {
      const selectedIds = permissionsList?.flatMap((item: any) =>
        item.data
          .filter((permission: any) => permission.has_role)
          .map((item: any) => item.id)
      );
      const submitData = {
        name: watch("name"),
        description: watch("description"),
        permission: [...selectedIds],
      };
      if (roleFlag === "add") {
        dispatch(createRole(submitData, setShowManageRole));
      } else {
        if (selectedRoleId) {
          dispatch(
            userRoleUpdate(
              { ...submitData, id: selectedRoleId },
              setShowManageRole,
              setSelectedRoleId
            )
          );
        }
      }
    }
  };

  const handleSaveAction = () => {
    if (roleFlag === "add") {
      roleFormRef?.current?.requestSubmit();
    } else {
      if (selectedRoleId) {
        roleFormRef?.current?.requestSubmit();
      } else {
        setRoleValidation("This field is required");
      }
    }
  };

  const onEditRoleClick = (roleId: any) => {
    if (roleId) {
      setRoleFlag("edit");
      setSelectedRoleId(roleId);
      dispatch(getUserRoleInfo(roleId));
      setRoleValidation("");
      document.getElementById("pills-add-new-role-tab")?.click();
    }
  };

  const onDeleteRole = () => {
    if (selectedRoleId) {
      dispatch(
        userRoleDelete(selectedRoleId, setShowDeleteModal, setSelectedRoleId)
      );
    }
  };

  const onCancelDeleteAction = () => {
    setSelectedRoleId("");
  };

  const handleDropdownClick = (key: any) => {
    if (showMenuDropdown === key) {
      setShowMenuDropdown(null);
    } else {
      setShowMenuDropdown(key);
    }
  };

  return (
    <div
      className="as-modal manage-role-modal"
      id="manage-role-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Manage Roles</h4>
              <button
                className="close-modal-btn"
                // onClick={() => showModal(false)}
              >
                {/* <CircleCloseIcon /> */}
              </button>
            </div>
            <div className="modal-body">
              <div className="tab-section">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-basic-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-basic-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Roles
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-add-new-role-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-add-new-role"
                      type="button"
                      role="tab"
                      aria-controls="pills-roles"
                      aria-selected="false"
                    >
                      {roleFlag === "add" ? "Add new role" : "Edit role"}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-permissions-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-permissions"
                      type="button"
                      role="tab"
                      aria-controls="pills-permissions"
                      aria-selected="false"
                    >
                      Permissions
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-basic-profile"
                    role="tabpanel"
                    aria-labelledby="pills-basic-profile-tab"
                    tabIndex={0}
                  >
                    {userRoleData && userRoleData?.length ? (
                      <div className="tab-panel-scroll-area">
                        {userRoleData?.map((item: any, key: any) => (
                          <div key={key} className="role-card">
                            <p className="role-title">{item?.name}</p>
                            <div className="relative">
                              <div className="dropdown more-dropdown" >
                                <button
                                  className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                                  data-bs-toggle="dropdown"
                                  aria-expanded={showMenuDropdown === key ? "true" : "false"}
                                  id={"dropdownMenuButton"+`${key}`}
                                  type="button"
                                  onClick={() => handleDropdownClick(key)}
                                >
                                  <DotsVerticalIcon />
                                </button>

                                <ul className={`dropdown-menu ${showMenuDropdown === key ? 'show' : ''}`} aria-labelledby={"dropdownMenuButton"+`${key}`}>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => onEditRoleClick(item?.id)}
                                    >
                                      <span className="me-2">
                                        <EditIcon />
                                      </span>
                                      Edit
                                    </button>
                                  </li>

                                  <li>
                                    <button
                                      className="dropdown-item text-danger"
                                      onClick={() => {
                                        setSelectedRoleId(item?.id);
                                        setShowDeleteModal(true);
                                      }}
                                    >
                                      <span className="me-2">
                                        <TrashIcon />
                                      </span>
                                      Delete
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}

                    <div className="d-flex btn-wrapper justify-content-between mt-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => setShowManageRole(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary close-modal-btn"
                        onClick={() =>
                          document
                            .getElementById("pills-add-new-role-tab")
                            ?.click()
                        }
                      >
                        Next
                      </button>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-add-new-role"
                    role="tabpanel"
                    aria-labelledby="pills-add-new-role-tab"
                    tabIndex={0}
                  >
                    <div className="form-wrapper">
                      <form
                        onSubmit={handleSubmit(handleNextAction)}
                        ref={roleFormRef}
                      >
                        <div className="tab-panel-scroll-area">
                          <div
                            className={`form-group ${
                              roleFlag === "add" ? "disabled" : ""
                            }`}
                          >
                            <h5 className="form-group-label">Select role</h5>
                            <select
                              id="role_option"
                              className="form-select form-group-dropdown"
                              disabled={
                                !userRoleData?.length || roleFlag === "add"
                              }
                              onChange={(e) =>
                                onEditRoleClick(e?.target?.value)
                              }
                              value={selectedRoleId}
                            >
                              <option value="" disabled>
                                Select a role
                              </option>
                              {userRoleData?.map((role: any, key: any) => (
                                <option
                                  key={key}
                                  value={role.id}
                                  className="form-group-option active selected"
                                >
                                  {role?.name}
                                </option>
                              ))}
                            </select>
                            {roleValidation ? (
                              <p className="validation-text">
                                {roleValidation}
                              </p>
                            ) : null}
                          </div>
                          <div className="d-flex align-items-center gap-4 mt-3">
                            <div
                              className={`form-radio-btn ${
                                !userRoleData?.length ? "disabled" : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="radioDefault"
                                id="radio1"
                                value="edit"
                                className={
                                  !userRoleData?.length ? "disabled" : ""
                                }
                                disabled={!userRoleData?.length}
                                checked={roleFlag === "edit"}
                                onChange={() => {
                                  setRoleFlag("edit");
                                  setSelectedRoleId("");
                                  reset();
                                  dispatch(setUserRoleInfo(""));
                                }}
                              />
                              <label htmlFor="radio1">Select Role</label>
                            </div>
                            <div className="form-radio-btn">
                              <input
                                type="radio"
                                name="radioDefault"
                                id="radio2"
                                value="add"
                                checked={
                                  !userRoleData?.length || roleFlag === "add"
                                }
                                onChange={() => {
                                  setRoleFlag("add");
                                  setSelectedRoleId("");
                                  reset();
                                  dispatch(setUserRoleInfo(""));
                                  setRoleValidation("");
                                }}
                              />
                              <label htmlFor="radio2">Add new Role</label>
                            </div>
                          </div>

                          <div className="form-group">
                            <h5 className="form-group-label">Role name</h5>
                            <input
                              type="text"
                              className="form-group-control"
                              {...register("name")}
                              name="name"
                              placeholder="Role Name"
                            />
                            <p className="validation-text">
                              {errors.name?.message}
                            </p>
                          </div>
                          <div className="form-group">
                            <h5 className="form-group-label">
                              Role Description
                            </h5>
                            <textarea
                              {...register("description")}
                              rows={4}
                              name="description"
                              className="form-group-textarea"
                              placeholder="Role Description"
                            ></textarea>
                            <p className="validation-text">
                              {errors.description?.message}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex btn-wrapper justify-content-between mt-3">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => setShowManageRole(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary close-modal-btn"
                            onClick={() => handleSaveAction()}
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-permissions"
                    role="tabpanel"
                    aria-labelledby="pills-permissions"
                    tabIndex={0}
                  >
                    <div className="form-wrapper">
                      <div className="tab-panel-scroll-area">
                        {permissionsList?.map((item: any, index: any) => (
                          <div key={index} className="permissions-section">
                            <h6 className="section-title">{item.title}</h6>
                            <ul className="permissions-list">
                              {item?.data.map((permission: any, idx: any) => (
                                <li key={idx} className="permission-item">
                                  <div className="form-check">
                                    <label
                                      className="form-check-label"
                                      htmlFor={`permission_${permission.id}`}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`permission_${permission.id}`}
                                        checked={permission.has_role}
                                        onChange={() =>
                                          handleCheckboxChange(permission?.id)
                                        }
                                      />
                                      <span className="form-check-label-text">
                                        {permission?.name}
                                      </span>
                                    </label>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                      <div className="d-flex btn-wrapper justify-content-between mt-3">
                        <button
                          onClick={() => setShowManageRole(false)}
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary close-modal-btn"
                          onClick={() => handleRoleSave()}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onDeleteRole}
          onCancel={onCancelDeleteAction}
          message={`The role ${
            userRoleData?.find((item: any) => item.id === selectedRoleId)?.name
          } will be permanently deleted.`}
        />
      )}
    </div>
  );
};

export default ManageRoleModal;
