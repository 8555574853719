import React, { useEffect, useRef } from "react";
import "./chat-bubble.scss";

// images
import avatar from "../../../../assets/images/avatar.png";
import { useDispatch } from "react-redux";
import { chatRead } from "../../../../redux/pages/chatSlice";

interface IDadminChat {
  adminChat: any;
}

const SenderChatBubble: React.FC<IDadminChat> = (props) => {
  const dispatch = useDispatch<any>();
  const { adminChat = {} } = props;
  const reversedAdminChat = [...adminChat].reverse();
  const chatBottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatBottomRef.current) {
      chatBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [adminChat]);

  return (
    <>
      {reversedAdminChat.map((chat: any, index: number) => (
        <div className="sender-chat-bubble-wrap" key={chat?.id}>
          {chat?.message_by === "student" ? (
            // <div className="avatar">
            //   {/* <img src={chat?.profile_image} className="avatar-img" alt="avatar" /> */}
            // </div>
            <div className="chat-body">
              <div className="chat-bubble">
                <p className="text">{chat?.message}</p>
              </div>
              <p className="time-stamp">{new Date().toLocaleString()}</p>
            </div>
          ) : null}
          {index === reversedAdminChat.length - 1 && (
            <div ref={chatBottomRef}></div>
          )}
        </div>
      ))}
    </>
  );
};

export default SenderChatBubble;
