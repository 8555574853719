import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import Subject from "./subject/subject";
import CourseList from "./courseList/courseList";
import ChapterMain from "./contents/chapterMain";
import ChapterList from "./contents/chapterList/chapterList";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../../redux/hooks";

interface courseDetails {
   
 };

const CourseMain = forwardRef<any, courseDetails>((props, ref) => {
   const { } = props;

   const subjectRef = useRef<any>();
   const chapterRef = useRef<any>();

   const [addFlag, setAddFlag] = useState<boolean>();
   const [selectedTab, setSelectedTab] = useState<number>(1);
   const [selectedItem, setSelectedItem] = useState<string>("chapter");

   //redux
   const dispatch = useDispatch<any>();
   const newSubject = useAppSelector((state) => state.course.newSubject);
   const selectedRow = useAppSelector((state) => state.course.selectedItemInfo);

   useImperativeHandle(ref, () => ({
      onSaveTrigger: () => {
         handleSaveClick();
      },
      onNextTrigger: () => {
         setSelectedTab(2);
      }
   }));

   useEffect(() => {
      if (newSubject) {
         setAddFlag(false);
      } else {
         setAddFlag(true);
      }
   }, [newSubject]);

   const handleSaveClick = () => {
      if (selectedTab === 1) {
         subjectRef?.current?.onSaveTrigger();
         setSelectedItem("chapter")
      } else {
         chapterRef?.current?.onSaveTrigger();
      }
   };

   const formTemplate = useCallback(() => {
      switch (selectedTab) {
         case 1: return (<Subject ref={subjectRef} setSelectedTab={setSelectedTab} />);
         case 2: return (<ChapterMain ref={chapterRef} setSelected={setSelectedItem}/>);
      }
   }, [selectedTab]);

   return (
      <div className="row gap-4 gap-xl-0">
         <div className="col-xl-7">
            <div className="card h-100">
               {formTemplate()}
               <div className="mt-auto">
                  <div className="line"></div>
                  <div className="d-flex justify-content-between inline-cta pt-3">
                     <button className="btn btn-link" disabled={selectedTab === 1} onClick={() => setSelectedTab(1)}>
                        Back
                     </button>
                     <button className="btn btn-link" onClick={handleSaveClick}>
                        {(selectedTab === 1 && newSubject) ? "Update" :
                           (selectedTab === 2 && selectedRow) ? "Update" :
                              (selectedTab === 2 && !selectedRow) ? "Save" : "Next"
                        }
                     </button>
                  </div>
               </div>
            </div>
         </div>
         {selectedTab === 1 ? <CourseList /> : <ChapterList selectedItem={selectedItem} />}
      </div>
   );
});

export default CourseMain;