import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { setErrors } from "./loginSlice";
import { axiosInstance } from "../interceptors/axiosConfig";

interface paymentInterface {
    isLoading: boolean;
    errors: any;
    paymentList:any;
}

const initialState:paymentInterface = {
    isLoading: false,
    errors: null,
    paymentList: [],
}

const paymentSlice = createSlice({
    name:"payment",
    initialState,
    reducers:{
      setErrors : (state, {payload}:PayloadAction<any>) =>{
         state.errors = payload
      },
      setIsLoading : (state, {payload} : PayloadAction<any>) => {
         state.isLoading = payload
      },
      setPaymentList : (state, {payload} : PayloadAction<any>) =>{
         state.paymentList = payload 
      }
}
})


export const getPaymentList = ():AppThunk => async(dispatch) =>{
   try{
    let URl = "/payment/list"
    const response = await axiosInstance({data: {url:URl,method:"post",data:null},token:true})
    if(response.data){
      console.log(response.data)
        if(response.data && response.data.status == "success"){
            dispatch(setPaymentList(response.data?.student))
            dispatch(setIsLoading(false))
        }
    }
   }catch(error:any){
      dispatch(setErrors(error))
      dispatch(setIsLoading(false))
   }
}



export const {
    setIsLoading,
    setPaymentList,
}
 = paymentSlice.actions


export default paymentSlice.reducer