import { Outlet } from "react-router-dom";
import Header from "../../common/components/Header/Header";
import Sidebar from "../../common/components/Sidebar/Sidebar";
import ErrorHandler from "../../common/components/ErrorHandler/ErrorHandler";
import "./post_login.scss";

const PostLogin = () => {
  return (
    <div className="as-main-wrapper">
      <div className="d-flex h-100">
        <Sidebar />
        <div className="main-wrapper">
          <Header />
          <div className="main-body-wrapper">
            <div className="main-body">
              <Outlet />
              <ErrorHandler/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostLogin;
