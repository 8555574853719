import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "../../../../redux/hooks";
import "../login.scss";

// icons
import { ReactComponent as MailIcon } from "../../../../assets/icons/icon-mail.svg";
import { ReactComponent as LockIcon } from "../../../../assets/icons/icon-lock.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/icon-eye.svg";
import { ReactComponent as EyeSlashedIcon } from "../../../../assets/icons/icon-eye-slash.svg";

import { loginFormSchema } from "../../../../validations/loginFormValidation";
import { login } from "../../../../redux/pages/loginSlice";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginFormSchema),
  });

  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginError, setLoginError] = useState("");

  //redux
  const dispatch = useDispatch<any>();
  const isLoading = useAppSelector((state) => state.login.isLoading);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    if (loginError) {
      const timeout = setTimeout(() => {
        setLoginError("");
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [loginError]);

  const handleSubmitForm = async (data: any) => {
    if (data?.email && data?.password) {
      dispatch(login(data, setLoginError));
    }
  };

  return (
    <div className="as-content-wrapper">
      <h1 className="as-login-title">Welcome Back</h1>
      <p className="as-login-subtitle">
        Welcome back, please enter you details to access dashboard
      </p>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        {loginError && (
          <div className="as-error-wrapper">
            <p className="error-message validation-text">{loginError}</p>
          </div>
        )}
        <div className="form-control-wrap mb-2">
          <label className="form-label">Email</label>
          <div className="icon-form-control">
            <div className="start-icon">
              <MailIcon />
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              {...register("email")}
            />
          </div>
          {errors.email && (
            <p className="validation-text">{errors.email.message}</p>
          )}
        </div>
        <div className="form-control-wrap mb-2">
          <label className="form-label">Password</label>
          <div className="icon-form-control">
            <div className="start-icon">
              <LockIcon />
            </div>
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              {...register("password")}
            />
            <div className="end-icon">
              <a onClick={handlePasswordVisibility}>
                {passwordVisible ? <EyeIcon /> : <EyeSlashedIcon />}
              </a>
            </div>
          </div>
          {errors.password && (
            <p className="validation-text">{errors.password.message}</p>
          )}
        </div>
        <div className="d-flex justify-content-end mb-3">
          <a className="link-danger">Forgot Password?</a>
        </div>
        <div>
          <button
            type="submit"
            className="btn btn-primary w-100"
            disabled={isLoading}
          >
            {isLoading ? "Logging in..." : "Login"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
