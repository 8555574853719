import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { setErrors } from "./loginSlice";
import { axiosInstance } from "../interceptors/axiosConfig";

interface studentsInterface {
    isLoading: boolean;
    errors: any;
    studentsList:any;
    studentsDetails:any;
}

const initialState:studentsInterface = {
    isLoading: false,
    errors: null,
    studentsList: [],
    studentsDetails: []
}

const studentSlice = createSlice({
    name:"student",
    initialState,
    reducers:{
      setErrors : (state, {payload}:PayloadAction<any>) =>{
         state.errors = payload
      },
      setIsLoading : (state, {payload} : PayloadAction<any>) => {
         state.isLoading = payload
      },
      setStudentsList : (state, {payload} : PayloadAction<any>) =>{
         state.studentsList = payload 
      },
      setStudentsDetails : (state, {payload} : PayloadAction<any>) =>{
         state.studentsDetails = payload
      }
}
})


export const getStudentsList = ():AppThunk => async(dispatch) =>{
   try{
    let URl = "/student/index"
    const response = await axiosInstance({data: {url:URl,method:"post",data:null},token:true})
    if(response.data){
      console.log(response.data)
        if(response.data && response.data.status == "success"){
            dispatch(setStudentsList(response.data?.student))
            dispatch(setIsLoading(false))
        }
    }
   }catch(error:any){
      dispatch(setErrors(error))
      dispatch(setIsLoading(false))
   }
}

export const getStudentsDetails = (id:any): AppThunk => async (dispatch) =>{
   try{
     let URL = "/student/details"
     const response = await axiosInstance({data: {url:URL,method:"post",data:id},token:true})
     if(response.data){
        if(response.data &&  response.data.status == "success"){
               dispatch(setStudentsDetails(response.data?.data))
               dispatch(setIsLoading(false))
        }
     }
   }catch(error:any){
    dispatch(setErrors(error))
    dispatch(setIsLoading(false))
   }
}



export const {
    setIsLoading,
    setStudentsList,
    setStudentsDetails
}
 = studentSlice.actions


export default studentSlice.reducer