import React, { useEffect, useState } from "react";
import "./students-details.scss";

// icons
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";
import { useDispatch } from "react-redux";
import { getStudentsDetails } from "../../../redux/pages/studentSlice";
import { useAppSelector } from "../../../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

const StudentsDetails = () => {

  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch<any>()

  useEffect(()=>{
    const data = { id: location.state };
     dispatch(getStudentsDetails(data))
  },[])

  const studentDetails = useAppSelector((state)=> state.student?.studentsDetails)
  


  

  return (
    <>
      <div className="students-details-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between align-items-center flex-wrap title-wrapper mb-1 mx-4"> 
          <button className="back-button" onClick={() => navigate(-1)}>
            <ArrowLeftIcon />
          </button>
          <div>
            <h2 className="title">Students</h2>
            <p className="desc mb-2">Details of all students</p>
          </div>
        </div>

        <div className="">
          <div className="details-wrap mx-4 pb-4">
            <div className="mb-3">
              <span className="control-sec-title mb-3">Basic</span>
            </div>
            <div className="row">
              <div className="col-lg-4 pb-4 pb-lg-0">
                <div className="card student-card">
                  <div className="avatar-wrap mx-auto mb-3">
                    {studentDetails?.student?.profile_image ? <img
                      src={studentDetails?.student?.profile_image}
                      alt="user image"
                    />
                   : 
                    <Avatar name={studentDetails?.student?.name} size="120"/>}
                  </div>
                  <form action="">
                    <div className="form-group mb-3">
                      <h5 className="form-group-label">Name</h5>
                      <input
                        type="text"
                        className="form-group-control"
                        name="name"
                        placeholder=""
                        value={studentDetails.student?.name}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <h5 className="form-group-label">Email</h5>
                      <input
                        type="text"
                        className="form-group-control"
                        name="email"
                        placeholder=""
                        value={studentDetails.student?.email}
                      />
                    </div>
                    <div className="form-group">
                      <h5 className="form-group-label">Phone Number</h5>
                      <input
                        type="text"
                        className="form-group-control"
                        name="phone number"
                        placeholder=""
                        value={studentDetails.student?.phone}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card courses-card">
                  <div className="card-wrapper">
                    <h3 className="card-subtitle mb-3">Enrolled Courses</h3>
                    <div className="card-scroll-area">
                      <ul className="course-list">
                        {studentDetails.course?.data?.map((item:any)=>{
                          return <>
                          <li>
                          <div className="course-list-card">
                            <div className="row">
                              <div className="col-4">
                                <p className="text">{item.name}</p>
                              </div>
                              <div className="col-4">
                                <p className="text text-center">{item.progress}%</p>
                              </div>
                              <div className="col-4">
                                <div className="d-flex justify-content-end">
                                  <a
                                    href=""
                                    className="btn-link fw-bold ms-auto text-decoration-none"
                                  >
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                          </>
                        })}
                        {/* <li>
                          <div className="course-list-card">
                            <div className="row">
                              <div className="col-4">
                                <p className="text">Programming in C</p>
                              </div>
                              <div className="col-4">
                                <p className="text text-center">30% Complete</p>
                              </div>
                              <div className="col-4">
                                <div className="d-flex justify-content-end">
                                  <a
                                    href=""
                                    className="btn-link fw-bold ms-auto text-decoration-none"
                                  >
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="course-list-card">
                            <div className="row">
                              <div className="col-4">
                                <p className="text">Programming in C</p>
                              </div>
                              <div className="col-4">
                                <p className="text text-center">30% Complete</p>
                              </div>
                              <div className="col-4">
                                <div className="d-flex justify-content-end">
                                  <a
                                    href=""
                                    className="btn-link fw-bold ms-auto text-decoration-none"
                                  >
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="course-list-card">
                            <div className="row">
                              <div className="col-4">
                                <p className="text">Programming in C</p>
                              </div>
                              <div className="col-4">
                                <p className="text text-center">30% Complete</p>
                              </div>
                              <div className="col-4">
                                <div className="d-flex justify-content-end">
                                  <a
                                    href=""
                                    className="btn-link fw-bold ms-auto text-decoration-none"
                                  >
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                    {/* pagination section */}
                    {/* <div className="d-flex align-items-center justify-content-center pt-3">
                      <nav aria-label="...">
                        <ul className="pagination mb-0">
                          <li className="page-item disabled me-4">
                            <a className="page-link">
                              <span className="">
                                <PaginationLeftArrowIcon />
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link">
                              1
                            </a>
                          </li>
                          <li className="page-item active" aria-current="page">
                            <a className="page-link">2</a>
                          </li>
                          <li className="page-item">
                            <a className="page-link">
                              3
                            </a>
                          </li>
                          <li className="page-item disabled">
                            <a className="page-link">
                              ..........
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link">
                              5
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link">
                              6
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link">
                              7
                            </a>
                          </li>
                          <li className="page-item ms-4">
                            <a className="page-link">
                              <span className="">
                                <PaginationRightArrowIcon />
                              </span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentsDetails;
