import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";


export interface offersInterface {
    isLoading: boolean;
    errors: any;
    offersList: any;
    allOffersList: any;
}

const initialState: offersInterface = {
    isLoading: false,
    errors: null,
    offersList: [],
    allOffersList: []
}

const offerSlice = createSlice({
    name: "offer",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
        setOffersList: (state, { payload }: PayloadAction<any>) => {
            state.offersList = payload;
        },
        setAllOffersList: (state, { payload }: PayloadAction<any>) => {
            state.allOffersList = payload;
        }
    }
})

export const getOffersList = (data: any): AppThunk => async (dispatch) => {
    try {
        let URL = "/offer/index"
        const response = await axiosInstance({ data: { url: URL, method: "post", data: data }, token: true })
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setOffersList(response.data.offers))
                dispatch(setLoading(false))
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false))
        dispatch(setErrors(error?.response))
    }
}

export const getAllOffersList = (): AppThunk => async (dispatch) => {
    try {
        let URL = "/offer/index"
        const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true })
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setAllOffersList(response.data?.offers))
                dispatch(setLoading(false))
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false))
        dispatch(setErrors(error?.response))
    }
}

export const createOffers = (data: any, showAll: boolean, setShowCreateModal: any): AppThunk => async (dispatch) => {
    try {
        let URL = "/offer/store"
        const response = await axiosInstance({ data: { url: URL, method: "post", data: data }, token: true })
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(getOffersList({ search: "" }))
                dispatch(setLoading(false))
                setShowCreateModal(false)
                showNotification({
                    message: "New Offers added successfully",
                    theme: "light",
                    type: "success"
                })
            }
        }
    }
    catch (error: any) {
        dispatch(setLoading(false))
        dispatch(setErrors(error?.response))
    }
}


export const updateOffers = (data: any, showAll: boolean, setShowEditModal: any): AppThunk => async (dispatch) => {
    try {
        let URL = "/offer/update"
        const response = await axiosInstance({ data: { url: URL, method: "post", data: data }, token: true })
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(getOffersList({ search: "" }))
                dispatch(setLoading(false))
                setShowEditModal(false)
                showNotification({
                    message: "Offers updated successfully",
                    theme: "light",
                    type: "success"
                })
            }
        }
    }
    catch (error: any) {
        dispatch(setLoading(false))
        dispatch(setErrors(error?.response))
    }
}

export const deleteOffers = (data: any, setShowDeleteModal: any, setOfferId: any): AppThunk => async (dispatch) => {
    try {
        let URL = "/offer/delete"
        const response = await axiosInstance({ data: { url: URL, method: "post", data: data }, token: true })
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(getOffersList({ search: "" }))
                dispatch(setLoading(false))
                setShowDeleteModal(false)
                setOfferId(null)
                showNotification({
                    message: "New Offers deleted successfully",
                    theme: "light",
                    type: "success"
                })
            }
        }
    }
    catch (error: any) {
        dispatch(setLoading(false))
        dispatch(setErrors(error?.response))
    }
}



export const {
    setErrors,
    setLoading,
    setOffersList,
    setAllOffersList
}
    = offerSlice.actions

export default offerSlice.reducer