import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "../../../category/Category.scss"
//icons
import { ReactComponent as CircleCloseIcon } from "../../../../../assets/icons/icon-close-circle.svg";
//redux
import { useAppSelector } from "../../../../../redux/hooks";
import { createSubProgram, getAllProgramsList, setAllProgramsList } from "../../../../../redux/pages/categorySlice";
import { subcategorySchema } from "../../../../../validations/subCategoryValidation";

interface CreateInfo {
  showModal: (value: boolean) => void;
}

const CreatesubCategoryModal: React.FC<CreateInfo> = (props) => {
  const {
    showModal = () => { },
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(subcategorySchema),
  });

  //redux
  const dispatch = useDispatch<any>();
  const [programsData, setProgramsData] = useState<any>([]);

  const programsList = useAppSelector((state) => state.programs.allProgramsList);

  const onCategoryFormSubmit = (data: any) => {
    if (data) {
      dispatch(createSubProgram(data, showModal));
    }
  };

  useEffect(() => {
    dispatch(getAllProgramsList());
    setValue("program_id",  "");
  }, [])

  useEffect(() => {
    if (programsList) {
      setProgramsData(programsList);
      dispatch(setAllProgramsList(""));
    }
  }, [programsList])

  return (
    <div
      className="as-modal create-category-modal"
      id="staticBackdrop"
      aria-hidden="true"
    >
      <div className="modal-dialog-wrapper">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header mb-3">
              <h4 className="modal-title">Create New Sub-Category</h4>
              <button
                className="close-button close-modal-btn"
                onClick={() => showModal(false)}
              >
                <CircleCloseIcon />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onCategoryFormSubmit)}>
                <div className={`category-form-control-wrap mb-3 ${!programsData?.length ? "disabled" : ""}`}>
                  <p className="form-label">Category</p>

                  <select
                    {...register("program_id")}
                    id="program_id"
                    className={`form-select form-group-dropdown ${errors?.program_id ? "error-border" : ""}`}>
                    <option value="" disabled> Select Category </option>
                    {programsData?.map((data: any, key: number) => (
                      <option key={key} value={data.id} className={"form-group-option"} >{data.name}</option>
                    ))}
                  </select>
                  <p className="validation-text">{errors?.program_id?.message?.toString()} </p>
                </div>


                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">Sub-Category Name </label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Sub-Category Name"
                    {...register("name")}
                    name="name"
                  />
                  <p className="validation-text">
                    {errors.name?.message?.toString()}
                  </p>
                </div>

                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">Price</label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Price"
                    {...register("price")}
                    name="price"
                  />
                  <p className="validation-text">
                    {errors.price?.message?.toString()}
                  </p>
                </div>
                <div className="category-form-control-wrap mb-4">
                  <label className="form-label">Description</label>
                  <textarea
                    {...register("description")}
                    name="description"
                    rows={4}
                    className="form-group-textarea"
                  ></textarea>
                </div>
                <div className="d-flex justify-content-between  mt-4">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger close-modal-btn"
                    onClick={() => showModal(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatesubCategoryModal;
