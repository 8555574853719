import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import "./createCourse.scss";

import { ReactComponent as AddCirclePlusIcon } from "../../../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../../assets/icons/icon-arrow-left.svg";

import CustomStepper from "../../../../../common/components/CustomStepper/CustomStepper";
import Content from "./manage/content/content";
import Feature from "./manage/feature/feature";
import CourseMain from "./manage/course/courseMain";
import CreateCategoryModal from "../../../category/manage/CreateCategory/CreateCategoryModal";

import { useAppSelector } from "../../../../../redux/hooks";
import { getChaptersList, setNewSubjectInfo } from "../../../../../redux/pages/courseSlice";
import { showNotification } from "../../../../../common/components/Toaster/Toast";

const CreateCourse = () => {
    const steps = [
        { label: "Course creation" },
        { label: "Content upload" },
        { label: "Addition info & publish" },
    ];

    const createFormRef = useRef<any>();
    const updateFormRef = useRef<any>();

    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState<any>(0);
    const [addFlag, setAddFlag] = useState<boolean>(true);
    const [showCategoryModal, setShowCategoryModal] = useState<any>(false);

    const dispatch = useDispatch<any>();
    const newSubject = useAppSelector((state) => state.course.newSubject);
    const chaptersList = useAppSelector((state) => state.course.chaptersList);
     
    
    useEffect(() => {
        return(() => {
            dispatch(setNewSubjectInfo(""));
        });
    },[]);

    useEffect(() => {
        if (newSubject) {
           setAddFlag(false);
           dispatch(getChaptersList(newSubject?.id));
        } else {
           setAddFlag(true);
        }
     }, [newSubject]);

    const nextBtnHandler = () => {
        switch (activeStep) {
            case 0: {
                if (addFlag) {
                    createFormRef?.current?.onSaveTrigger();
                } else {
                    if (newSubject && newSubject?.chapter?.length) {
                        setActiveStep(1);
                    } else if(newSubject && chaptersList && chaptersList?.length){
                        setActiveStep(1);
                    } else {
                        createFormRef?.current?.onNextTrigger();
                        showNotification({
                            message: "Atleast one chapter should be added",
                            theme: "light",
                            type: "warn"
                        });
                    }
                }
                break;
            };
            case 1: {
                setActiveStep(2);
                break;
            };
            case 2: {
                updateFormRef?.current?.onNextTrigger();
                break;
            }
        }
    };

    const previousBtnHandler = () => {
        if(activeStep !== 0 && activeStep !== steps.length) {
            setActiveStep(activeStep - 1)
        } else {
            navigate('/courses');
        }
    };

    const createFormTemplate = useCallback(() => {
        switch (activeStep) {
            case 0:
                return (
                    <CourseMain ref={createFormRef}
                  
                    />
                );
            case 1:
                return (
                    <Content />
                );
            case 2:
                return (
                    <Feature ref={updateFormRef}/>
                );
        }
    }, [activeStep]);

    return (
        <div className="create-course-wrapper">
            {/* title section */}
            <div className="d-flex justify-content-between flex-wrap title-wrapper mb-3 mx-4">
                <button className="back-button" onClick={() => navigate('/courses')}>
                    <ArrowLeftIcon />
                </button>
                <div>
                    <h2 className="title">Create Courses</h2>
                    <p className="desc mb-2">Create new course.</p>
                </div>
                {/* <div className="d-flex al gap-3">
                    <a className="btn btn-primary" onClick={() => setShowCategoryModal(true)}>
                        <span className="me-1">
                            <AddCirclePlusIcon />
                        </span>
                        Create Category
                    </a>
                </div> */}
            </div>

            {/* stepper section */}
            <div className="stepper-wrapper mx-4">
                <CustomStepper
                    steps={steps}
                    className="stepper-container"
                    stepClassName="step-wrapper"
                    nonLinear={false}
                    activeStep={activeStep}
                    connectorStyleConfig={{
                        completedColor: "#125ae3",
                        activeColor: "#125ae3",
                        disabledColor: "#125ae3",
                        style: activeStep !== steps.length ? "solid" : "dashed",
                        size: 2,
                        stepSize: "2em",
                    }}
                    styleConfig={{
                        activeBgColor: "#125ae3",
                        completedBgColor: "#125ae3",
                        inactiveBgColor: "transparent",
                        activeTextColor: "#fff",
                        completedTextColor: "#fff",
                        inactiveTextColor: "#125ae3",
                        labelFontSize: "1rem",
                        fontWeight: "500",
                    }}
                />
            </div>

            <div className="d-flex flex-column">
                <div className="ps-4 me-4">
                    <div className="card-wrapper">{createFormTemplate()}</div>
                </div>
                <div className="row">
                    <div className="col-xl-7">
                        <div className="navigation-btn-wrapper ms-4 pt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <button className={`btn btn-outline-primary`}
                                    onClick={() => previousBtnHandler()}
                                > {activeStep !== 0 && activeStep !== steps.length ? "Previous" : "Cancel"}
                                </button>

                                <button className="btn btn-outline-primary" onClick={() => nextBtnHandler()}>
                                    {activeStep !== steps.length - 1 ? "Next" : newSubject?.status === "active" ? "Save Changes" : "Publish"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showCategoryModal && (
                <CreateCategoryModal
                    showModal={setShowCategoryModal}
                />
            )}
        </div>
    );
};

export default CreateCourse;