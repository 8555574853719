import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";


export interface couponsInterface {
    isLoading:boolean;
    errors:any;
    couponsList:any;
    allCouponsList:any;
}

const initialState : couponsInterface = {
    isLoading:false,
    errors:null,
    couponsList:[],
    allCouponsList:[]
}

const couponSlice = createSlice({
    name:"coupon",
    initialState,
    reducers:{
       setErrors: (state, {payload} : PayloadAction<any>) =>{
          state.errors = payload;
       },
       setLoading: (state, {payload} : PayloadAction<any>) =>{
          state.isLoading = payload;
       },
       setCouponsList : (state, {payload} : PayloadAction<any>) =>{
         state.couponsList = payload;
       },
       setAllCouponsList : (state, {payload} : PayloadAction<any>) =>{
         state.allCouponsList = payload;
       }
    }
})

export const getCouponsList = (data: any):AppThunk => async(dispatch) =>{
      try{
        let URL = "/coupon/index"
        const response = await axiosInstance({data:{url: URL, method: "post", data: data}, token:true})
        console.log(response)
        if(response.data){
            if(response.data.status && response.data.status === "success"){
               dispatch(setCouponsList(response.data?.coupons))
               dispatch(setLoading(false))
            }
        }
      }catch(error:any){
         dispatch(setLoading(false))
         dispatch(setErrors(error?.response))
      }
}

export const getAllCouponsList = ():AppThunk => async(dispatch) =>{
    try{
      let URL = "/coupon/index"
      const response = await axiosInstance({data:{url:URL,method:"post",data:null},token:true})
      if(response.data){
          if(response.data.status && response.data.status === "success"){
             dispatch(setAllCouponsList(response.data?.coupons))
             dispatch(setLoading(false))
          }
      }
    }catch(error:any){
       dispatch(setLoading(false))
       dispatch(setErrors(error?.response))
    }
}

export const createCoupons = (data:any,showAll:boolean,setShowCreateModal:any):AppThunk => async(dispatch) => {
    try{
        let URL = "/coupon/store"
        const response = await axiosInstance({data:{url:URL,method:"post",data:data},token:true})
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(getCouponsList({ search: "" }))
                dispatch(setLoading(false))
                setShowCreateModal(false)
                showNotification({
                    message: "New Coupons added successfully",
                    theme: "light",
                    type: "success"
                })
            }
        }
    }
    catch(error:any){
        dispatch(setLoading(false))
        dispatch(setErrors(error?.response))
    }
}


export const updateCoupons = (data:any,showAll:boolean,setShowEditModal:any):AppThunk => async(dispatch) => {
    try{
        let URL = "/coupon/update"
        const response = await axiosInstance({data:{url:URL,method:"post",data:data},token:true})
        if(response.data){
            if(response.data.status && response.data.status === "success"){
                 dispatch(getCouponsList({ search: "" }))
                 dispatch(setLoading(false))
                 setShowEditModal(false)
                 showNotification({
                    message: "Coupons updated successfully",
                    theme: "light",
                    type: "success"
                 })
                }
        }
    }
    catch(error:any){
        dispatch(setLoading(false))
        dispatch(setErrors(error?.response))
    }
}

export const deleteCoupons = (data:any,setShowDeleteModal: any,setCouponId:any):AppThunk => async(dispatch) => {
    try{
        let URL = "/coupon/delete"
        const response = await axiosInstance({data:{url:URL,method:"post",data:data},token:true})
        if(response.data){
            if(response.data.status && response.data.status === "success"){
                 dispatch(getCouponsList({ search: "" }))
                 dispatch(setLoading(false))
                 setShowDeleteModal(false)
                 setCouponId(null)
                 showNotification({
                    message: "New Coupons deleted successfully",
                    theme: "light",
                    type: "success"
                 })
                }
        }
    }
    catch(error:any){
        dispatch(setLoading(false))
        dispatch(setErrors(error?.response))
    }
}



export const  {
   setErrors,
   setLoading,
   setCouponsList,
   setAllCouponsList
}
= couponSlice.actions

export default couponSlice.reducer