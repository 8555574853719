import React, { memo, useCallback } from "react";
//icons
import { ReactComponent as SortIcon } from "../../../assets/icons/icon-sort.svg";


interface IAdvancedSort {
   sortOrder?: string;
   sortBy: string;
   onSort: (data: any) => void;
}

const Search: React.FC<IAdvancedSort> = (props) => {
   const {
      sortOrder = "",
      sortBy = "",
      onSort = () => { }
   } = props;

   const handleSortChange = useCallback((field: string) => {
      if(sortOrder && sortOrder === "asc") {
         onSort({sortOrder: "desc", sortBy: `${field}`, sort: `${field}_desc`});
      } else if(sortOrder && sortOrder === "desc") {
         onSort({sortOrder: "asc", sortBy: `${field}`, sort: `${field}_asc`});
      } else if(sortOrder === "") {
         onSort({sortOrder: "asc", sortBy: `${field}`, sort: `${field}_asc`});
      }
   }, [sortOrder, sortBy]);

   return (
      <div className="d-flex gap-3">
         <div className="dropdown sort-dropdown">
            <button
               className="control-btn dropdown-toggle"
               data-bs-toggle="dropdown"
               aria-expanded="false"
            >
               <span className="me-2">
                  <SortIcon />
               </span>
               Sort
            </button>

            <ul className="dropdown-menu">
               <li>
                  <button className={`dropdown-item ${sortBy === "name" ? "active" : ""}`}
                     onClick={() => handleSortChange("name")}>
                     Name
                  </button>
               </li>
               <li>
                  <button className={`dropdown-item ${sortBy === "date" ? "active" : ""  }`}
                     onClick={() => handleSortChange("date")}>
                     Date
                  </button>
               </li>
            </ul>
         </div>
      </div>
   );
};

export default memo(Search);
