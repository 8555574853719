import React, { useEffect, useState } from "react";

import "./EditOfferModal.scss";

// icons
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/icons/icon-calendar.svg";
import { ReactComponent as RefreshIcon } from "../../../../../assets/icons/icon-refresh.svg";
import { offerSchema } from "../../../../../validations/offersValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createOffers, updateOffers } from "../../../../../redux/pages/offersSlice";
import { getCourseList } from "../../../../../redux/pages/courseSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import moment from "moment";

interface EditOffer {
  showAll?: boolean;
  showModal: (value: boolean) => void;
  programInfo: any;
}

const EditOffersModal: React.FC<EditOffer> = (props) => {

  const {
    showAll = false,
    showModal = () => { },
    programInfo = () => { }
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(offerSchema),
  });

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (programInfo) {
      setValue("title", programInfo?.title);
      setValue("descount_type", programInfo?.descount_type);
      setValue("descount_value", programInfo?.descount_value);
      setValue("valid_from", programInfo?.valid_from);
      setValue("valid_to", programInfo?.valid_to);
      setValue("status", programInfo?.status);
    }
  }, [programInfo]);

  const onEditFormSubmit = (data: any) => {
    if (data) {
      const submitData = {
        ...data,
        valid_from: moment(data?.valid_from).format("YYYY-MM-DD"),
        valid_to: moment(data?.valid_to).format("YYYY-MM-DD"),
        id: programInfo.id
      };
      dispatch(updateOffers(submitData, showAll, showModal));
    }
  };


  return (
    <div
      className="as-modal create-offers-modal"
      id="add-role-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog-wrapper">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header mb-2">
              <h4 className="modal-title">Edit Offer</h4>
            </div>
            <div className="modal-body">


              {/* for course selection */}

              <form onSubmit={handleSubmit(onEditFormSubmit)} >
                <div className="form-wrapper">
                  <div className="sub-section mb-3">
                    <h5 className="form-group-label">Coupon Code</h5>
                    <div className="form-group">
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-group-control fw-bold"
                          {...register("title")}
                          name="title"
                          placeholder="Coupon Code"
                        />
                        <p className="validation-text">{errors.title?.message}</p>
                        {/* <button className="ms-2">
                        <RefreshIcon />
                      </button>          */}
                      </div>
                    </div>
                  </div>

                  <div className="sub-section mb-3">
                    <div className="form-group">
                      <h5 className="form-group-label mb-1">Discount</h5>
                      <div className="d-flex align-items-center gap-4 mb-2">
                        <div className="form-radio-btn">
                          <input
                            type="radio"
                            id="DiscountPercentage"
                            checked={watch("descount_type") === "percentage"}
                            onClick={() => setValue("descount_type", "percentage")}
                          />
                          <label htmlFor="DiscountPercentage">Percentage</label>
                        </div>
                        <div className="form-radio-btn">
                          <input
                            type="radio"
                            id="DiscountValue"
                            checked={watch("descount_type") === "amount"}
                            onClick={() => setValue("descount_type", "amount")}
                          />
                          <label htmlFor="DiscountValue">Value</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      {watch("descount_type") === "percentage" ?
                        <>
                          <h5 className="form-group-label">Discount percentage</h5>
                          <div className="icon-form-control">
                            {/* <div className="start-icon">
                              <a>%</a>
                            </div> */}
                            <input
                              type="text"
                              className="form-group-control fw-bold"
                              placeholder="Discount percentage"
                              {...register("descount_value")}
                            />
                            <p className="validation-text">{errors.descount_value?.message}</p>
                          </div>
                        </>
                        :
                        <>
                          <h5 className="form-group-label">Discount value</h5>
                          <div className="icon-form-control">
                            <input
                              type="text"
                              className="form-group-control fw-bold"
                              placeholder="Discount value"
                              {...register("descount_value")}
                            />
                            <p className="validation-text">{errors.descount_value?.message}</p>
                          </div>
                        </>}
                    </div>
                  </div>

                  <div className="sub-section mb-5">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <h5 className="form-group-label">Start date</h5>
                          <div className="icon-form-control">
                            <div className="start-icon">
                            </div>
                            <input
                              type="date"
                              value={moment(watch("valid_from")).format("YYYY-MM-DD")}
                              className="form-group-control"
                              placeholder="Start date"
                              {...register("valid_from")}
                              min="2000-01-01"
                              max="2500-12-31"
                            />
                            <p className="validation-text">{errors.valid_from?.message}</p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-1">
                          <div className="d-flex align-items-end h-100">
                            <p className="desc-text mb-2">To</p>
                          </div>
                        </div> */}
                      <div className="col-6">
                        <div className="form-group">
                          <h5 className="form-group-label">End date</h5>
                          <div className="icon-form-control">
                            <div className="start-icon">
                            </div>
                            <input
                              type="date"
                              value={moment(watch("valid_to")).format("YYYY-MM-DD")}
                              className="form-group-control"
                              placeholder="End date"
                              {...register("valid_to")}
                              min="2000-01-01"
                              max="2500-12-31"
                            />
                            <p className="validation-text">{errors.valid_to?.message}</p>
                          </div>
                        </div>

                      </div>
                      <div className="form-group mt-3">
                        <h5 className="form-group-label mb-2">Status</h5>
                        <div className="d-flex align-items-center gap-4 mb-2">
                          <div className="form-radio-btn">
                            <input
                              type="radio"
                              id="StatusActive"
                              checked={watch("status") === "active"}
                              onClick={() => setValue("status", "active")}
                            />
                            <label htmlFor="StatusActive">Active</label>
                          </div>
                          <div className="form-radio-btn">
                            <input
                              type="radio"
                              id="StatusInactive"
                              checked={watch("status") === "inactive"}
                              onClick={() => setValue("status", "inactive")}
                            />
                            <label htmlFor="StatusInactive">Inactive</label>
                          </div>
                        </div>
                        <p className="validation-text">{errors.status?.message}</p>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex btn-wrapper justify-content-between mt-3">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => showModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary close-modal-btn"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOffersModal;
