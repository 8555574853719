import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";

export interface categoryState {
    errors: any;
    isLoading: boolean;
    programList: any;
    allProgramsList: any;
    subProgramsList: any;
}

const initialState: categoryState = {
    errors: null,
    isLoading: false,
    programList: "",
    allProgramsList: "",
    subProgramsList: "",
};

const categorySlice = createSlice({
    name: "programs",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
        setProgramsList: (state, { payload }: PayloadAction<any>) => {
            state.programList = payload;
        },
        setAllProgramsList: (state, { payload }: PayloadAction<any>) => {
            state.allProgramsList = payload;
        },
        setSubProgramsList: (state, { payload }: PayloadAction<any>) => {
            state.subProgramsList = payload;
        },
    }
});

export const getProgramsList = (data: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/program/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setProgramsList(response.data?.programs));
                dispatch(setLoading(false));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getAllProgramsList = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/program/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: null }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setAllProgramsList(response.data?.programs));
                dispatch(setLoading(false));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const createProgram = (data: any, setShowCreateModal: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/program/store';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setProgramsList(response.data.program));
                dispatch(setLoading(false));
                setShowCreateModal(false);
                showNotification({
                    message: "New Category added successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const updateProgram = (data: any, setShowEditModal: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/program/update';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setProgramsList(response.data.program));
                dispatch(setLoading(false));
                setShowEditModal(false);
                showNotification({
                    message: "Category updated successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const deleteProgram = (data: any, setShowDeleteModal: any, setCategoryId: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/program/delete';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setProgramsList(response.data.program));
                dispatch(setLoading(false));
                setShowDeleteModal(false);
                setCategoryId(null);
                showNotification({
                    message: "Category deleted successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getAllSubProgramsList = (data: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subprogram/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setSubProgramsList(response.data?.subprograms));
                dispatch(setLoading(false));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getSubProgramsList = (program_id:any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subprogram/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data:program_id }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setSubProgramsList(response.data?.subprograms));
                dispatch(setLoading(false));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const createSubProgram = (data: any, setShowCreateModal: any,): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subprogram/store';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setSubProgramsList(response.data?.subprogram));
                dispatch(setLoading(false));
                setShowCreateModal(false);
                showNotification({
                    message: "New Sub-Category added successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const updateSubCategory = (data: any, setShowEditModal: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        console.log(data,"data")
        let URL = '/subprogram/update';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setSubProgramsList(response.data?.subprogram));
                dispatch(setLoading(false));
                setShowEditModal(false);
                showNotification({
                    message: "Sub-Category updated successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const deleteSubProgram = (data: any, setShowDeleteModal: any, setCategoryId: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subprogram/delete';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setSubProgramsList(response.data?.subprogram));
                dispatch(setLoading(false));
                setShowDeleteModal(false);
                setCategoryId(null);
                showNotification({
                    message: "Sub-Category deleted successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};



export const {
    setErrors,
    setLoading,
    setProgramsList,
    setAllProgramsList,
    setSubProgramsList,
} = categorySlice.actions;

export default categorySlice.reducer;