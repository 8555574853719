import * as yup from "yup";

export const createCourseSchema = yup.object({
   name: yup.string().trim().required("Name field is required"),
   program_id: yup.string().trim().required("Select a category"),
   sub_program_id: yup.string().trim(),
   description: yup.string().trim().required("Description field is required"),
   status: yup.string(),
   price: yup.number().typeError('Price must be a number').nullable().min(0, "Add a valid price").required("Price field is required"),
   short_description: yup.string().trim().required("Short description field is required"),
   features: yup.array(),
   offer_type: yup.string(),
   offer_value: yup.number().typeError('Offer value must be a number').positive("Add a valid price"),
   video_link: yup.string()
});

export const createChapterSchema = yup.object().shape({
   name: yup.string().trim().required("Chapter name is required"),
   description: yup.string().trim().required("Description field is required"),
   has_test: yup.bool(),
});

export const createTestSchema = yup.object().shape({
   name: yup.string().trim().required("Test name is required"),
   description: yup.string().trim().required("Description field is required"),
});

export const createLectureSchema = yup.object().shape({
   name: yup.string().trim().required("Title field is required"),
   description: yup.string().trim().required("Description field is required"),
   video_link: yup.string().trim().required("Lecture video is required"),
   notes: yup.string(),
});

export const createQuestionSchema = yup.object().shape({
   question: yup.string().trim().required("Add question"),
   answer: yup.string().trim().required("Select answer from options"),
   point:  yup.number().required("Select point"),
   optionValue: yup.string().trim().required("Add valid option"),
});