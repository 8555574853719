import * as yup from "yup";

export const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required.")
    .email("Please provide a valid email.")
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
      "Please provide a valid email."
    ),
  password: yup
    .string()
    .trim()
    .required("Password is required.")
    .min(5, "Password must contain at least five characters"),
});
