import React, { useState } from "react";
import "./notification.scss";

// icons
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";
import { ReactComponent as GreenArrowIcon } from "../../../assets/icons/icon-arrow-up-green.svg";
import { ReactComponent as RedArrowIcon } from "../../../assets/icons/icon-arrow-up-red.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/icon-chevron-down.svg";
import { ReactComponent as NotificationDotsIcon } from "../../../assets/icons/icon-notification-dots.svg";

const Notification = () => {
  return (
    <>
      <div className="overview-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between align-items-center flex-wrap title-wrapper mb-3 mx-4">
          {/* <button className="back-button">
            <ArrowLeftIcon />
          </button> */}
          <div>
            <h2 className="title">Notifications</h2>
            <p className="desc mb-2">All notification on the app</p>
          </div>
        </div>

        <div className="">
          <div className="details-wrap mx-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="notification-accordion-wrapper">
                  <div
                    className="accordion accordion-flush notification-accordion"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <div className="d-flex">
                            <span className="icon">
                              <NotificationDotsIcon />
                            </span>
                            <div className="title">
                              Device Notification: Important Update
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="text">
                            Placeholder content for this accordion, which is
                            intended to demonstrate the{" "}
                            <code>.accordion-flush</code> class. This is the
                            first item's accordion body. sdflkj slfkjdl
                            flskjflkdsjflslj lskjflkj lkskflksjd lfjslfj sljfslj
                            fslkjf lsjsl fjlsjflskjf ihsoghweoldk
                            lsdfsojfodsijfowelajl dosjfosflsdkjflds jfsojfodsfj
                            osjfo sjfosfjso
                          </p>
                          <p className="date mt-2">10 May 2023</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <div className="d-flex">
                            <span className="icon">
                              <NotificationDotsIcon />
                            </span>
                            <div className="title">
                              Device Notification: Important Update
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="text">
                            Placeholder content for this accordion, which is
                            intended to demonstrate the{" "}
                            <code>.accordion-flush</code> class. This is the
                            first item's accordion body.
                          </p>
                          <p className="date mt-2">10 May 2023</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <div className="d-flex">
                            <span className="icon">
                              <NotificationDotsIcon />
                            </span>
                            <div className="title">
                              Device Notification: Important Update
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="text">
                            Placeholder content for this accordion, which is
                            intended to demonstrate the{" "}
                            <code>.accordion-flush</code> class. This is the
                            first item's accordion body.
                          </p>
                          <p className="date mt-2">10 May 2023</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
