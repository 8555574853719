import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../../../redux/hooks";
import { createTestSchema } from "../../../../../../../../validations/courseValidations";
import { createTest, updateTest } from "../../../../../../../../redux/pages/courseSlice";
import { showNotification } from "../../../../../../../../common/components/Toaster/Toast";

interface testDetails { 
   selectedChapter: any;
   isEditMode: any;
   setIsEditMode: (data: any) => void;
};

const CreateTest = forwardRef<any, testDetails>((props, ref) => {
   const {
      selectedChapter = "",
      isEditMode = false,
      setIsEditMode = () => {}
    } = props;

   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      reset,
      clearErrors,
   } = useForm({
      resolver: yupResolver(createTestSchema),
      defaultValues: {
         name: "",
         description: "",
      }
   });

   const testFormRef = useRef<any>();

   //redux
   const dispatch = useDispatch<any>();
   const newSubject = useAppSelector((state) => state.course.newSubject);
   const selectedRowItem = useAppSelector((state) => state.course.selectedItemInfo);
   const chapterTestsList = useAppSelector((state) => state.course.chapterTestsList);
   const selectedContentInfo = useAppSelector((state) => state.course.selectedContentInfo);

   useImperativeHandle(ref, () => ({
      onSaveTrigger: () => {
         testFormRef?.current?.requestSubmit();
      }
   }));

   useEffect(() => {
      if(selectedContentInfo && chapterTestsList && chapterTestsList?.length) {
         setIsEditMode(true);
         const findTest = chapterTestsList?.find((item: any) => item?.id === selectedContentInfo);
         if(findTest) {
            setValue("name", findTest?.name);
            setValue("description", findTest?.description);
            clearErrors(["name", "description"]);
         }
      } else {
         setIsEditMode(false);
         reset();
      }
   },[selectedContentInfo, chapterTestsList]);

   useEffect(() => {
      if(isEditMode) {
         reset();
      }
   },[selectedChapter]);

   useEffect(() => {
      if (selectedRowItem) {
         setValue("name", selectedRowItem?.name);
         setValue("description", selectedRowItem?.description);
         clearErrors(["name", "description"]);
      }
   }, [selectedRowItem]);

   const onSubmit = (data: any) => {
      if (data) {
         if(selectedChapter) {
            if(isEditMode) {
               const body = {subject_id:  newSubject?.id, chapter_id: selectedChapter};
               const submitData = { ...data, subject_id:  newSubject?.id, chapter_id: selectedChapter, id: selectedContentInfo };
               dispatch(updateTest(submitData, reset, body, setIsEditMode));
            } else {
               if (selectedRowItem) {
                  const body = {subject_id:  newSubject?.id, chapter_id: selectedChapter};
                  const submitData = { ...data, subject_id:  newSubject?.id, chapter_id: selectedChapter, id: selectedRowItem?.id };
                  dispatch(updateTest(submitData, reset, body));
               } else {
                  if (newSubject && newSubject?.id) {
                     const body = {subject_id:  newSubject?.id, chapter_id: selectedChapter};
                     const submitData = { ...data, subject_id:  newSubject?.id, chapter_id: selectedChapter, };
                     dispatch(createTest(submitData, reset, body));
                  }
               }
            }
         } else {
            showNotification({
               message: "Select a chapter to save test",
               theme: "light",
               type: "error"
            });
         }
      }
   };

   const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
         const videoUrl = `http://example.com/public/videos/${file.name}`;
         // setValue("video_link", videoUrl);
      }
   };

   return (
      <div className="form-wrapper">
         <form ref={testFormRef} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-4">
               <h5 className="form-group-label">Test name</h5>
               <h6 className="form-group-hint">
                  Name of the test
               </h6>
               <input
                  {...register("name")}
                  type="text"
                  className={`form-group-control ${errors?.name ? "error-border" : ""}`}
               />
               <p className="validation-text">{errors?.name?.message}</p>
            </div>

            <div className="form-group mb-4">
               <h5 className="form-group-label">Description</h5>
               <h6 className="form-group-hint">
                  Write description about the test
               </h6>

               <textarea
                  {...register("description")}
                  id="test_desc"
                  rows={5}
                  className={`form-group-textarea ${errors?.description ? "error-border" : ""}`}
               ></textarea>
               <p className="validation-text">{errors?.description?.message}</p>
            </div>

            <div className="form-group mb-4">
               <h5 className="form-group-label">Upload Question Set</h5>
               <h6 className="form-group-hint">Upload question set in .xlx/.xls format only</h6>
               <input
                  type="file"
                  accept="application/csv"
                  onChange={handleFileUpload}
                  // className={`form-group-control ${errors?.video_link ? "error-border" : ""}`}
                  className={`form-group-control`}
               />
               {/* <p className="validation-text">{errors?.video_link?.message}</p> */}
            </div>
         </form>
      </div>
   );
});

export default memo(CreateTest);