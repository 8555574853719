import React, { useEffect, useState } from "react";
import "./doubts.scss";

// icons
import { ReactComponent as SearchNormalIcon } from "../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as BlueNoteIcon } from "../../../assets/icons/icon-note-blue.svg";
import { ReactComponent as ChevronRightIcon } from "../../../assets/icons/icon-chevron-right.svg";
import { ReactComponent as SendIcon } from "../../../assets/icons/icon-send.svg";

// images
import avatar from "../../../assets/images/avatar.png";
import EmptyMsgIllustration from "../../../assets/images/empty-msg-illustration.svg";

// components
import UserChatBubble from "./components/UserChatBubble";
import SenderChatBubble from "./components/SenderChatBubble";
import { useDispatch } from "react-redux";
import { chatList, chatRead, chatStore } from "../../../redux/pages/chatSlice";
import subject from "../Course/manage/AddCourse/manage/course/subject/subject";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";

const Doubts = () => {
  const navigate = useNavigate();
  const chatInfo = useAppSelector((state) => state.chat.getChatList);
  const [chatSubject, setChatSubject] = useState<any>([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [chat, setChat] = useState<any>([]);
  const [InputMessage, setInputMessage] = useState<string>("");
  const [subject_id, setSubject_Id] = useState<any>([]);
  const [student_id, setStudent_id] = useState<any>([]);
  const [student_Name, setStudent_Name] = useState<any>([]);
  const [unreadMessages, setUnreadMessages] = useState<boolean>(false);
  const [isStudentSelected, setIsStudentSelected] = useState<boolean>(false);
  const [activeTime, setActiveTime] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");
  const [filteredCourseList, setFilteredCourseList] = useState<any>([]);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(chatList());
  }, []);

  useEffect(() => {
    if (chatInfo) {
      setChatSubject(chatInfo?.subject);
    }
  }, [chatInfo]);

  const filterCourses = (input: string) => {
    setInputValue(input);
    const filteredList = chatSubject.filter((course: any) =>
      course.name.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredCourseList(filteredList);
    setInputValue(input);
  };

  const handleChatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(e.target.value);
  };

  const handleSubjectClick = (subject: any) => {
    setSubject_Id(subject);
    setShowDropdown((prevShowDropdown) => {
      const newShowDropdown =
        selectedSubject === subject ? !prevShowDropdown : true;
      setSelectedSubject(subject); // Set the selected subject
      console.log("Show Dropdown:", newShowDropdown);
      return newShowDropdown;
    });
  };

  const handleStudentClick = (student: any) => {
    setIsStudentSelected(true);
    dispatch(chatRead({ student_id, subject_id }));
    setChat(student?.chat);
    setStudent_id(student?.id);
    setStudent_Name(student?.name);
    setActiveTime(student?.message_at);
    setUnreadMessages(false);
  };

  // const messageSendHandler = () => {
  //   const message = InputMessage;
  //   console.log(subject_id, 'sub id', message, ':message', student_id, ':std id');
  //   if (subject_id && message && student_id) {
  //     dispatch(chatStore({ student_id, subject_id, message }))
  //       .then(() => {
  //         setInputMessage(""); // Set the input message to an empty string after sending the message
  //       })
  //       .finally(() => {
  //         dispatch(chatList()); // Move dispatch inside the 'finally' block to ensure it runs after setting the input message
  //       });
  //   }
  // };

  const messageSendHandler = () => {
    const message = InputMessage;
    console.log(
      subject_id,
      "sub id",
      message,
      ":message",
      student_id,
      ":std id"
    );

    if (subject_id && message && student_id) {
      dispatch(chatStore({ student_id, subject_id, message }))
        .then(() => {
          setInputMessage(""); // Set the input message to an empty string after sending the message
        })
        .catch((error: any) => {
          console.error("Error storing message:", error);
        })
        .finally(() => {
          dispatch(chatList()); // Move dispatch inside the 'finally' block to ensure it runs after setting the input message
        });
    }
  };

  return (
    <>
      <div className="doubts-wrapper">
        <div className="chat-side-bar">
          {/* title component */}
          <div className="title-sec mb-3">
            <button className="me-2">
              <span className="icon">
                <button
                  className="back-button"
                  onClick={() => navigate("/courses")}
                >
                  <ArrowLeftIcon />
                </button>
              </span>
            </button>
            <h3 className="chat-side-bar-title">Doubts</h3>
          </div>
          {/* search component */}
          <div className="search-box">
            <a className="search-icon-button">
              <SearchNormalIcon />
            </a>
            <input
              type="text"
              placeholder="Search course"
              className="search-form-control"
              value={inputValue}
              onChange={(e) => filterCourses(e.target.value)}
            />
          </div>
          {inputValue === ""
            ? chatSubject?.map((subjects: any) => (
                <div className="side-bar-scroll-area" key={subjects?.id}>
                  {/* icon card */}

                  <button
                    key={subjects?.id}
                    className="icon-card"
                    onClick={() => handleSubjectClick(subjects?.id)}
                  >
                    <div key={subjects?.id} className="icon-card-wrapper">
                      <div key={subjects?.id}>
                        <div className="icon-wrap" key={subjects?.id}>
                          <span className="icon" key={subjects?.id}>
                            <BlueNoteIcon />
                          </span>
                        </div>
                      </div>
                      <div className="icon-body unread" key={subjects?.id}>
                        <div className="text-wrapper" key={subjects.id}>
                          <p className="title" key={subjects?.id}>
                            {subjects?.name}
                          </p>
                          {subjects?.new_chat > 0 && (
                            <span className="notification-dot"></span>
                          )}
                        </div>
                        <span className="icon" key={subjects?.id}>
                          <ChevronRightIcon />
                        </span>
                      </div>
                    </div>
                  </button>

                  {/* icon card */}

                  {showDropdown && selectedSubject === subjects?.id && (
                    <div className="student-list-dropdown">
                      {subjects?.students.map((student: any, index: any) => (
                        <button
                          className="icon-card"
                          key={index}
                          onClick={() => handleStudentClick(student)}
                        >
                          <div className="icon-card-wrapper student-list-dropdown">
                            <div>
                              <div className="avatar">
                                <img src={avatar} alt="avatar" />
                              </div>
                            </div>
                            <div className="icon-body unread">
                              <div className="text-wrapper">
                                <p className="title">{student?.name}</p>
                                {student?.is_new > 0 && (
                                  <span className="notification-dot"></span>
                                )}
                                <span className="notification-dot"></span>
                              </div>
                              <span className="icon">
                                <ChevronRightIcon />
                              </span>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              ))
            : filteredCourseList?.map((subjects: any) => (
                <div className="side-bar-scroll-area" key={subjects?.id}>
                  {/* icon card */}

                  <button
                    key={subjects?.id}
                    className="icon-card"
                    onClick={() => handleSubjectClick(subjects?.id)}
                  >
                    <div key={subjects?.id} className="icon-card-wrapper">
                      <div key={subjects?.id}>
                        <div className="icon-wrap" key={subjects?.id}>
                          <span className="icon" key={subjects?.id}>
                            <BlueNoteIcon />
                          </span>
                        </div>
                      </div>
                      <div className="icon-body unread" key={subjects?.id}>
                        <div className="text-wrapper" key={subjects.id}>
                          <p className="title" key={subjects?.id}>
                            {subjects?.name}
                          </p>
                          {subjects?.new_chat > 0 && (
                            <span className="notification-dot"></span>
                          )}
                        </div>
                        <span className="icon" key={subjects?.id}>
                          <ChevronRightIcon />
                        </span>
                      </div>
                    </div>
                  </button>

                  {/* icon card */}

                  {showDropdown && selectedSubject === subjects?.id && (
                    <div className="student-list-dropdown">
                      {subjects?.students.map((student: any, index: any) => (
                        <button
                          className="icon-card"
                          key={index}
                          onClick={() => handleStudentClick(student)}
                        >
                          <div className="icon-card-wrapper student-list-dropdown">
                            <div>
                              <div className="avatar">
                                <img src={avatar} alt="avatar" />
                              </div>
                            </div>
                            <div className="icon-body unread">
                              <div className="text-wrapper">
                                <p className="title">{student?.name}</p>
                                {student?.is_new > 0 && (
                                  <span className="notification-dot"></span>
                                )}
                                <span className="notification-dot"></span>
                              </div>
                              <span className="icon">
                                <ChevronRightIcon />
                              </span>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              ))}
        </div>

        {isStudentSelected ? (
          <div className="chat-body">
            <div className="chat-container">
              {/* user details section */}
              <div className="chat-header">
                <div className="avatar">
                  <img src={avatar} alt="avatar" className="avatar-img" />
                </div>
                <div>
                  <h6 className="sender-name">{student_Name}</h6>
                  <p className="sender-description">{activeTime}</p>
                </div>
              </div>
              {/* chat display section */}
              <div className="chat-area">
                <SenderChatBubble adminChat={chat} />
                <UserChatBubble userChat={chat} />
              </div>

              {/* message sending section */}
              <div className="chat-footer">
                <div className="chat-box">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type a message"
                    value={InputMessage}
                    onChange={handleChatChange}
                  />
                  <button className="send-btn" onClick={messageSendHandler}>
                    <SendIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          /* empty component - show this when no course or the chat is selected */
          <div className="empty-container">
            <div className="wrapper">
              <img
                src={EmptyMsgIllustration}
                className="mb-4"
                alt="empty chat icon"
              />
              <p className="text">
                Select a subject to see students having doubts
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Doubts;
