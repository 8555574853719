import * as yup from "yup";

export const categorySchema = yup.object().shape({
   name: yup.string().required("Category name is required"),
   price: yup
      .number()
      .typeError("Invalid amount")
      .required("Category price is required")
      .min(0, "Minimum value should be 0")
      .positive()
      .integer(),
   description: yup.string().required("Description is required"),
});
