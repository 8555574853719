import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";

export interface courseState {
    errors: any;
    isLoading: boolean;
    courseList: any;
    inactiveCourseList: any;
    newSubject: any;
    subjectDetails: any;
    chaptersList: any;
    chapterTestsList: any;
    subjectTestsList: any;
    selectedItemInfo: any;
    tutorialList: any;
    questionsList: any;
    selectedContentInfo: any;
    tutorialDetails: any;
}

const initialState: courseState = {
    errors: null,
    isLoading: false,
    courseList: [],
    inactiveCourseList: [],
    newSubject: "",
    subjectDetails: "",
    chaptersList: [],
    chapterTestsList: [],
    subjectTestsList: [],
    selectedItemInfo: null,
    tutorialList: [],
    questionsList: [],
    selectedContentInfo: null,
    tutorialDetails: null,
};

const courseSlice = createSlice({
    name: "course",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
        setCourseList: (state, { payload }: PayloadAction<any>) => {
            state.courseList = payload;
        },
        setInactiveCourseList: (state, { payload }: PayloadAction<any>) => {
            state.inactiveCourseList = payload;
        },
        setNewSubjectInfo: (state, { payload }: PayloadAction<any>) => {
            state.newSubject = payload;
        },
        setSubjectDetails: (state, { payload }: PayloadAction<any>) => {
            state.subjectDetails = payload;
        },
        setChaptersList: (state, { payload }: PayloadAction<any>) => {
            state.chaptersList = payload;
        },
        setChapterTestsList: (state, { payload }: PayloadAction<any>) => {
            state.chapterTestsList = payload;
        },
        setSubjectTestsList: (state, { payload }: PayloadAction<any>) => {
            state.subjectTestsList = payload;
        },
        setSelectedItemInfo: (state, { payload }: PayloadAction<any>) => {
            state.selectedItemInfo = payload;
        },
        setTutorialList: (state, { payload }: PayloadAction<any>) => {
            state.tutorialList = payload;
        },
        setQuestionsList: (state, { payload }: PayloadAction<any>) => {
            state.questionsList = payload;
        },
        setSelectedContentInfo: (state, { payload }: PayloadAction<any>) => {
            state.selectedContentInfo = payload;
        },
        setTutorialDetails: (state, { payload }: PayloadAction<any>) => {
            state.tutorialDetails = payload;
        },
    }
});

export const getCourseList = (data: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subject/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                if (response.data.subjects) {
                    dispatch(setCourseList(response.data.subjects?.data));
                }
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getInactiveCourseList = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subject/list/inactive';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: null }, token: true });
        if (response.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                dispatch(setLoading(false));
                if (response?.data?.subjects) {
                    dispatch(setInactiveCourseList(response?.data?.subjects));
                }
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getCourseDetails = (subjectId: any, setIsNavigate?: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subject/details';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: {id: subjectId} }, token: true });
        if (response.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                dispatch(setLoading(false));
                dispatch(setSubjectDetails(response?.data?.subjects));
                if(setIsNavigate) {
                    setIsNavigate(true);
                }
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const createCourse = (courseData: any, setSelectedTab?: any, chapterData?: any, formData?: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subject/store';
        console.log(courseData,"coursedata")
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: courseData }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                dispatch(setLoading(false));
                dispatch(setNewSubjectInfo(response?.data?.subjects));
                setSelectedTab(2);
                showNotification({
                    message: "New Course created successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const updateCourse = (courseData: any, setSelectedTab?: any, setIsUpdated?: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = 'subject/update';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: courseData }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                dispatch(setLoading(false));
                dispatch(setNewSubjectInfo(response?.data?.subjects));
                if(setSelectedTab) {
                    setSelectedTab(2);
                };
                if(setIsUpdated) {
                    setIsUpdated(true);
                };

                showNotification({
                    message: "Course updated successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const deleteCourse = (body: any, setShowModal: any, setCourseId?: any, setIsNavigate?: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subject/delete';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: body }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                dispatch(setLoading(false));
                // dispatch(getCourseList());
                setShowModal(false);
                if(setCourseId) {
                    setCourseId(null);
                }
                if(setIsNavigate) {
                    setIsNavigate(true);
                }
                showNotification({
                    message: "Course deleted successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getChaptersList = (subjectId: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/chapter/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: {subject_id : subjectId} }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                dispatch(setLoading(false));
                dispatch(setChaptersList(response?.data?.chatpters));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const createChapter = (chapterData: any, reset: any, subjectId: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/chapter/store';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: chapterData }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                reset();
                dispatch(setLoading(false));
                dispatch(getChaptersList(subjectId));
                showNotification({
                    message: "Chapter created successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const updateChapter = (chapterData: any, reset: any, subjectId: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/chapter/update';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: chapterData }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                reset();
                dispatch(setLoading(false));
                dispatch(getChaptersList(subjectId));
                dispatch(setSelectedItemInfo(null));
                showNotification({
                    message: "Chapter updated successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const deleteChapter = (chapterData: any, subjectId: any, setShowModal: any, setChapterId: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/chapter/delete';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: chapterData }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                dispatch(getChaptersList(subjectId));
                dispatch(setSelectedItemInfo(null));
                setShowModal(false);
                setChapterId(null);
                showNotification({
                    message: "Chapter deleted successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getTestsList = (data: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/test/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                dispatch(setLoading(false));
                if(data && data?.chapter_id) {
                    dispatch(setChapterTestsList(response?.data?.test));
                } else {
                    dispatch(setSubjectTestsList(response?.data?.test));
                }
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const createTest = (testData: any, reset: any, body: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/test/store';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: testData }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                reset();
                dispatch(setLoading(false));
                dispatch(getTestsList(body));
                showNotification({
                    message: "Test created successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const updateTest = (testData: any, reset: any, body: any, setEditMode?: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = 'test/update';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: testData }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                reset();
                dispatch(setLoading(false));
                dispatch(getTestsList(body));
                dispatch(setSelectedItemInfo(null));
                dispatch(setSelectedContentInfo(null));
                setEditMode(false);
                showNotification({
                    message: "Test updated successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const deleteTest = (submitData: any, body: any, setShowModal: any, setTestId: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = 'test/delete';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: submitData }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                dispatch(setLoading(false));
                dispatch(getTestsList(body));
                dispatch(setSelectedItemInfo(null));
                setShowModal(false);
                setTestId(null);
                showNotification({
                    message: "Test deleted successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getTutorialList = (body: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/tutorial/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: body }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                dispatch(setTutorialList(response?.data?.data?.tutorials));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getTutorialDetails = (body: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/tutorial/details';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: body }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                dispatch(setTutorialDetails(response?.data?.tutorials));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const createTutorial = (submitData: any, body: any, reset: any, fileUploadRef: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/tutorial/store';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: submitData }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                dispatch(getTutorialList(body));
                reset();
                fileUploadRef?.current.removeFile();
                showNotification({
                    message: "Lecture added successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const updateTutorial = (submitData: any, body: any, reset: any, fileUploadRef: any, setEditMode: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/tutorial/update';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: submitData }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                dispatch(getTutorialList(body));
                dispatch(setSelectedContentInfo(null));
                setEditMode(false);
                reset();
                fileUploadRef?.current.removeFile();
                showNotification({
                    message: "Lecture added successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const deleteTutorial = (submitData: any, body: any, setShowModal: any, setSelectedId: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = 'tutorial/delete';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: submitData }, token: true });
        if (response?.data) {
            if (response?.data?.status && response?.data?.status === 'success') {
                dispatch(setLoading(false));
                dispatch(getTutorialList(body));
                setShowModal(false);
                setSelectedId(null);
                showNotification({
                    message: "Tutorial deleted successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const getQuestionsList = (submitData: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = 'test/question/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: submitData }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                dispatch(setQuestionsList(response?.data?.question));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const addNewTestQuestion = (submitData: any, testId: any, setIsAddMode: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/test/question/store';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: submitData }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                const data = {test_id: testId};
                dispatch(getQuestionsList(data));
                setIsAddMode(false);
                showNotification({
                    message: "Question added successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const updateQuestion = (submitData: any, testId: any, setIsEditMode: any, setSelectedItem: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/test/question/update';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: submitData }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                const data = {test_id: testId};
                dispatch(getQuestionsList(data));
                setIsEditMode(false);
                setSelectedItem(null);
                showNotification({
                    message: "Question updated successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const deleteQuestion = (body: any, testId: any, setShowDeleteModal: any, setSelectedItem: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/test/question/delete';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: body }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                const data = {test_id: testId};
                dispatch(getQuestionsList(data));
                setShowDeleteModal(false);
                setSelectedItem(null);
                showNotification({
                    message: "Question deleted successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const {
    setLoading,
    setErrors,
    setCourseList,
    setInactiveCourseList,
    setNewSubjectInfo,
    setSubjectDetails,
    setChaptersList,
    setChapterTestsList,
    setSubjectTestsList,
    setSelectedItemInfo,
    setTutorialList,
    setQuestionsList,
    setSelectedContentInfo,
    setTutorialDetails,
} = courseSlice.actions;

export default courseSlice.reducer;