import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// icon
import { ReactComponent as PlayCircleIcon } from "../../../../../../../../assets/icons/icon-play-circle.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../../../../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as TrashIcon } from "../../../../../../../../assets/icons/icon-trash.svg";
import { ReactComponent as EditIcon } from "../../../../../../../../assets/icons/icon-edit.svg";
import { ReactComponent as CirclePlayIcon } from "../../../../../../../../assets/icons/icon-play-circle.svg";
import { ReactComponent as ClipboardIcon } from "../../../../../../../../assets/icons/icon-clipboard-text.svg";

// images
import FilsIllustraion from "../../../../../../../../assets/images/files-illustraion.png";

import { useAppSelector } from "../../../../../../../../redux/hooks";
import {
  deleteTest,
  deleteTutorial,
  getTestsList,
  getTutorialList,
  setSelectedContentInfo,
} from "../../../../../../../../redux/pages/courseSlice";
import DeleteModal from "../../../../../../../../common/components/Modal/DeleteModal/DeleteModal";

interface lecList {
  selectedItem: any;
  selectedChapter: any;
  chapterName: string;
  setSelectedTest: (data: any) => void;
  setIsQuestionMode: (data: any) => void;
}

const LectureList: React.FC<lecList> = (props) => {
  const {
    selectedItem = "lecture",
    selectedChapter = "",
    chapterName = "",
    setSelectedTest = () => {},
    setIsQuestionMode = () => {},
  } = props;

  const [tutorialData, setTutorialData] = useState<any>();
  const [testData, setTestData] = useState<any>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [message, setMessage] = useState<string>("");

  const dispatch = useDispatch<any>();
  const subjectInfo = useAppSelector((state) => state.course.newSubject);
  const tutorialList = useAppSelector((state) => state.course.tutorialList);
  const chapterTestsList = useAppSelector(
    (state) => state.course.chapterTestsList
  );

  useEffect(() => {
    if (selectedChapter) {
      if (selectedItem === "lecture") {
        const body = { chapter_id: selectedChapter };
        dispatch(getTutorialList(body));
      } else {
        if (subjectInfo?.id) {
          const body = {
            subject_id: subjectInfo?.id,
            chapter_id: selectedChapter,
          };
          dispatch(getTestsList(body));
        }
      }
    }

    return () => {
      dispatch(setSelectedContentInfo(null));
    };
  }, [selectedChapter, selectedItem]);

  useEffect(() => {
    setTutorialData(tutorialList);
  }, [tutorialList]);

  useEffect(() => {
    setTestData(chapterTestsList);
  }, [chapterTestsList]);

  const onPlayClicked = () => {};

  const onEditClicked = (data: any) => {
    if (data) {
      dispatch(setSelectedContentInfo(data));
    }
  };

  const onDeleteClicked = (data: any) => {
    if (data) {
      let infoMessage = "";
      if (selectedItem === "lecture") {
        infoMessage = `The lecture ${
          tutorialData?.find((item: any) => item.id === data)?.name
        } will be permanently deleted.`;
      } else {
        infoMessage = `The test ${
          testData?.find((item: any) => item.id === data)?.name
        } will be permanently deleted.`;
      }
      setSelectedId(data);
      setMessage(infoMessage);
      setShowDeleteModal(true);
    }
  };

  const onDeleteProgram = () => {
    if (selectedId) {
      const submitData = { id: selectedId };
      if (selectedItem === "lecture") {
        const body = {
          chapter_id: selectedChapter,
        };
        dispatch(
          deleteTutorial(submitData, body, setShowDeleteModal, setSelectedId)
        );
      } else {
        const body = {
          subject_id: subjectInfo?.id,
          chapter_id: selectedChapter,
        };
        dispatch(
          deleteTest(submitData, body, setShowDeleteModal, setSelectedId)
        );
      }
    }
  };

  const onCancelDeleteAction = () => {
    setSelectedId(null);
    setShowDeleteModal(false);
  };

  const testTemplate = (
    <div className="pe-2">
      {selectedChapter ? (
        <>
          {testData && testData?.length ? (
            <>
              {testData?.map((item: any, key: any) => (
                <button key={key} className="video-card mb-2">
                  <div className="d-flex  align-items-center">
                    <p className="title">{item?.name}</p>
                  </div>
                  <td width="10%" className="text-end">
                    <div className="dropdown more-dropdown">
                      <button
                        className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <DotsVerticalIcon />
                      </button>

                      <ul className="dropdown-menu more-dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSelectedTest(item?.id);
                              setIsQuestionMode(true);
                            }}
                          >
                            <span className="me-2 clipboard-icon">
                              <ClipboardIcon />
                            </span>
                            View
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              onEditClicked(item?.id);
                              setIsQuestionMode(false);
                            }}
                          >
                            <span className="me-2">
                              <EditIcon />
                            </span>
                            Edit
                          </a>
                        </li>
                        <li>
                          <button
                            className="dropdown-item text-danger"
                            onClick={() => onDeleteClicked(item?.id)}
                          >
                            <span className="me-2">
                              <TrashIcon />
                            </span>
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </button>
              ))}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center empty-msg-wrap">
              <div className="empty-msg">
                <div className="files-illustraion mb-3">
                  <img
                    src={FilsIllustraion}
                    className="img-fluid"
                    alt="empty illustraion"
                  />
                </div>
                <p className="description">No Tests added</p>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center empty-msg-wrap">
          <div className="empty-msg">
            <div className="files-illustraion mb-3">
              <img
                src={FilsIllustraion}
                className="img-fluid"
                alt="empty illustraion"
              />
            </div>
            <p className="description">Select chapter to view tests</p>
          </div>
        </div>
      )}
    </div>
  );

  const lectureTemplate = (
    <div className="pe-2">
      {selectedChapter ? (
        <>
          {tutorialData && tutorialData?.length ? (
            <>
              {tutorialData?.map((item: any, key: any) => (
                <button key={key} className="video-card mb-2">
                  <div className="d-flex  align-items-center">
                    <div className="thumbnail-img">
                      <img
                        src="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZGF0YSUyMHNjaWVuY2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                        alt="video thumbnail"
                      />
                      <div className="dark-overlay"></div>
                      <button className="play-button">
                        <PlayCircleIcon />
                      </button>
                    </div>
                    <p className="title">{item?.name}</p>
                  </div>
                  <p className="duration"></p>
                  <td width="10%" className="text-end">
                    <div className="dropdown more-dropdown">
                      <button
                        className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <DotsVerticalIcon />
                      </button>

                      <ul className="dropdown-menu more-dropdown-menu">
                        <li>
                          <a className="dropdown-item">
                            <span className="me-2 play-icon">
                              <CirclePlayIcon />
                            </span>
                            Play
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => onEditClicked(item?.id)}
                          >
                            <span className="me-2">
                              <EditIcon />
                            </span>
                            Edit
                          </a>
                        </li>
                        <li>
                          <button
                            className="dropdown-item text-danger"
                            onClick={() => onDeleteClicked(item?.id)}
                          >
                            <span className="me-2">
                              <TrashIcon />
                            </span>
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </button>
              ))}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center empty-msg-wrap">
              <div className="empty-msg">
                <div className="files-illustraion mb-3">
                  <img
                    src={FilsIllustraion}
                    className="img-fluid"
                    alt="empty illustraion"
                  />
                </div>
                <p className="description">No Lectures uploaded</p>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center empty-msg-wrap">
          <div className="empty-msg">
            <div className="files-illustraion mb-3">
              <img
                src={FilsIllustraion}
                className="img-fluid"
                alt="empty illustraion"
              />
            </div>
            <p className="description">Select chapter to view lectures</p>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="card h-100">
        <div className="card-title-wrapper">
          <h3 className="card-title">{chapterName}</h3>
          <h4 className="card-subtitle mb-4">
            {selectedItem === "lecture" ? "Topics" : "Tests"}
          </h4>
          <div className="line"></div>
        </div>
        <div className="card-inner-scroll-area">
          {selectedItem === "lecture" ? lectureTemplate : testTemplate}
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onDeleteProgram}
          onCancel={onCancelDeleteAction}
          message={message}
        />
      )}
    </>
  );
};

export default LectureList;
