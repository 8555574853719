import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { setErrors } from "./loginSlice";
import { axiosInstance } from "../interceptors/axiosConfig";
import Overview from "../../views/post_login/overview/Overview";

interface overviewInterface {
    isLoading: boolean;
    errors: any;
    overviewList:any;
    graphList:any;
}

const initialState: overviewInterface = {
    isLoading: false,
    errors: null,
    overviewList: [],
    graphList: []
}

const overviewSlice = createSlice({
    name:"overview",
    initialState,
    reducers:{
      setErrors : (state, {payload}:PayloadAction<any>) =>{
         state.errors = payload
      },
      setIsLoading : (state, {payload} : PayloadAction<any>) => {
         state.isLoading = payload
      },
      setOverviewList : (state, {payload} : PayloadAction<any>) =>{
         state.overviewList = payload 
      },
      setGraphDetails : (state, {payload} : PayloadAction<any>) =>{
         state.graphList = payload
      }
}
})


export const getoverview = ():AppThunk => async(dispatch) =>{
   try{
    let URl = "/list/overview"
    const response = await axiosInstance({data: {url:URl,method:"post",data:null},token:true})
    if(response.data){
      console.log(response.data)
        if(response.data && response.data.status == "success"){
            dispatch(setOverviewList(response.data?.student))
            dispatch(setIsLoading(false))
        }
    }
   }catch(error:any){
      dispatch(setErrors(error))
      dispatch(setIsLoading(false))
   }
}

export const getgraphdata = (id:any): AppThunk => async (dispatch) =>{
   try{
     let URL = "/overview/graph"
     const response = await axiosInstance({data: {url:URL,method:"post",data:id},token:true})
     if(response.data){
        if(response.data &&  response.data.status == "success"){
               dispatch(setGraphDetails(response.data?.data))
               dispatch(setIsLoading(false))
        }
     }
   }catch(error:any){
    dispatch(setErrors(error))
    dispatch(setIsLoading(false))
   }
}



export const {
    setIsLoading,
    setOverviewList,
    setGraphDetails
}
 = overviewSlice.actions


export default overviewSlice.reducer