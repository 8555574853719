import { combineReducers } from "@reduxjs/toolkit";
import loginSlice from "./pages/loginSlice";
import courseListSlice from "./pages/courseSlice";
import categorySlice from "./pages/categorySlice";
import userSlice from "./pages/userSlice";
import offersSlice from "./pages/offersSlice";
import coupensSlice from "./pages/couponsSlice";
import studentSlice from "./pages/studentSlice";
import bannerSlice from "./pages/bannerSlice";
import profileSlice from "./pages/profileSlice";
import paymentSlice from "./pages/paymentSlice";
import OverviewSlice from "./pages/OverviewSlice";
import chatSlice from "./pages/chatSlice";

const rootReducer = combineReducers({
    login: loginSlice,
    course: courseListSlice,
    programs: categorySlice, 
    user:userSlice,
    offer:offersSlice,
    coupen:coupensSlice,
    student:studentSlice,
    banner:bannerSlice,
    profile:profileSlice,
    payment:paymentSlice,
    overview:OverviewSlice,
    chat:chatSlice,
    
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;