import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./sidebar.scss";

// icons
import iconChart from "../../../assets/icons/icon-chart.svg";
import iconBook from "../../../assets/icons/icon-book.svg";
import iconDiagram from "../../../assets/icons/icon-diagram.svg";
import iconSecurityUser from "../../../assets/icons/icon-security-user.svg";
import iconMessageQuestion from "../../../assets/icons/icon-message-question.svg";
import iconStar from "../../../assets/icons/icon-star.svg";
import iconSettings from "../../../assets/icons/icon-setting.svg";
import iconLogout from "../../../assets/icons/icon-logout.svg";
import iconGraduationCap from "../../../assets/icons/icon-graduation-cap.svg";
import iconGallery from "../../../assets/icons/icon-gallery.svg";
import iconDollars from "../../../assets/icons/icon-dollar-circle.svg";

// redux
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/pages/loginSlice";
import { useAppSelector } from "../../../redux/hooks";
import { showUI } from "../../utility/permissionManager/permissionUtility";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  //redux
  const dispatch = useDispatch<any>();
  const userAuthenticated = useAppSelector((state) => state.login.isAuthenticated);
  const permissions = useAppSelector((state) => state.login.userPermissions);

  useEffect(() => {
    if (!userAuthenticated) {
      navigate("/login");
    }
  }, [userAuthenticated]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="as-sidebar-wrapper h-100">
      <div className="as-sidebar">
        <div className="as-logo-wrap">
          <a href="" className="as-logo">
            Assurance
          </a>
        </div>
        <div className="as-sidebar-body-wrapper">
          <div className="as-sidebar-body">
            {/* sub section general */}
            <div className="as-side-bar-sub-section">
              <p className="as-sidebar-subtitle">General</p>
              <Link to="/" className="d-block mb-1">
                <div
                  className={`as-sidebar-link ${location.pathname === "/" ? "active" : ""
                    }`}
                >
                  <img
                    src={iconChart}
                    alt=""
                    className="as-sidebar-link-icon"
                  />
                  <p className="as-sidebar-link-text">Overview</p>
                </div>
              </Link>
              {showUI("Payment List", permissions) && <Link to="/payments" className="d-block mb-1">
                <div
                  className={`as-sidebar-link ${location.pathname === "/payments" ? "active" : ""
                    }`}
                >
                  <img
                    src={iconDollars}
                    alt=""
                    className="as-sidebar-link-icon"
                  />
                  <p className="as-sidebar-link-text">Payments</p>
                </div>
              </Link>}
            </div>
            {/* sub section courses */}
            <div className="as-side-bar-sub-section">
              <p className="as-sidebar-subtitle">Courses</p>
              {showUI("Course List", permissions) && <Link to="/courses" className="d-block mb-1">
                <div
                  className={`as-sidebar-link ${location.pathname === "/courses" ? "active" : ""
                    }`}
                >
                  <img src={iconBook} alt="" className="as-sidebar-link-icon" />
                  <p className="as-sidebar-link-text">Courses</p>
                </div>
              </Link>}
              {showUI("Course List", permissions) && <Link to="/banner" className="d-block mb-1">
                <div
                  className={`as-sidebar-link ${location.pathname === "/banner" ? "active" : ""
                    }`}
                >
                  <img
                    src={iconGallery}
                    alt=""
                    className="as-sidebar-link-icon"
                  />
                  <p className="as-sidebar-link-text">Banner</p>
                </div>
              </Link>}

              {showUI("Offer List", permissions) && <Link to="/offers" className="d-block mb-1">
                <div
                  className={`as-sidebar-link ${location.pathname === "/offers" ? "active" : ""
                    }`}
                >
                  <img src={iconStar} alt="" className="as-sidebar-link-icon" />
                  <p className="as-sidebar-link-text">Offers</p>
                </div>
              </Link>}
            </div>
            {/* sub section users */}
            <div className="as-side-bar-sub-section">
              <p className="as-sidebar-subtitle">Users</p>
              {showUI("User List", permissions) && <Link to="/users" className="d-block mb-1">
                <div
                  className={`as-sidebar-link ${location.pathname === "/users" ? "active" : ""
                    }`}
                >
                  <img
                    src={iconSecurityUser}
                    alt=""
                    className="as-sidebar-link-icon"
                  />
                  <p className="as-sidebar-link-text">Manage</p>
                </div>
              </Link>}
            </div>
            {/* sub section students */}
            <div className="as-side-bar-sub-section">
              <p className="as-sidebar-subtitle">Students</p>
              {showUI("Student List", permissions) && <Link to="/students" className="d-block mb-1">
                <div
                  className={`as-sidebar-link ${location.pathname === "/students" ? "active" : ""
                    }`}
                >
                  <img
                    src={iconGraduationCap}
                    alt=""
                    className="as-sidebar-link-icon"
                  />
                  <p className="as-sidebar-link-text">Students</p>
                </div>
              </Link>}
              {showUI("Doubts View and Replay", permissions) && <Link to="/doubts" className="d-block mb-1">
                <div
                  className={`as-sidebar-link ${location.pathname === "/doubts" ? "active" : ""
                    }`}
                >
                  <img
                    src={iconMessageQuestion}
                    alt=""
                    className="as-sidebar-link-icon"
                  />
                  <p className="as-sidebar-link-text">Doubts</p>
                </div>
              </Link>}
            </div>
            {/* sub section activity */}
            {/* <div className="as-side-bar-sub-section">
              <p className="as-sidebar-subtitle">Activity</p>
            </div> */}
          </div>
          <div className="as-sidebar-body-btm">
            {/* sub section */}
            <div className="as-side-bar-sub-section">
              <Link to="/settings" className="d-block mb-1">
                <div
                  className={`as-sidebar-link ${location.pathname === "/settings" ? "active" : ""
                    }`}
                >
                  <img
                    src={iconSettings}
                    alt=""
                    className="as-sidebar-link-icon"
                  />
                  <p className="as-sidebar-link-text">Settings</p>
                </div>
              </Link>
              <Link to="" className="d-block mb-1" onClick={handleLogout}>
                <div
                  className={`as-sidebar-link ${location.pathname === "/logout" ? "active" : ""
                    }`}
                >
                  <img
                    src={iconLogout}
                    alt=""
                    className="as-sidebar-link-icon"
                  />
                  <p className="as-sidebar-link-text">Logout</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
