import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ReactComponent as PictureIcon } from "../../../../../../../assets/icons/icon-picture.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/icon-plus.svg";
import { ReactComponent as EditSquareIcon } from "../../../../../../../assets/icons/icon-edit-square.svg";
import { createCourseSchema } from "../../../../../../../validations/courseValidations";
import { showNotification } from "../../../../../../../common/components/Toaster/Toast";
import { ReactComponent as TrashIcon } from "../../../../../../../assets/icons/icon-trash.svg";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { updateCourse } from "../../../../../../../redux/pages/courseSlice";
import { useNavigate } from "react-router-dom";

interface courseDetails {}

const Feature = forwardRef<any, courseDetails>((props, ref) => {
  const {} = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(createCourseSchema),
    defaultValues: {
      name: "",
      program_id: "",
      description: "",
      features: [],
      status: "",
      short_description: "",
      offer_type: "",
    },
  });

  const additionalInfoRef = useRef<any>();
  const featureRef = useRef<any>();
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState<any>(null);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [features, setFeatures] = useState<any>([]);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const newSubject = useAppSelector((state) => state.course.newSubject);

  useImperativeHandle(ref, () => ({
    onNextTrigger: () => {
      additionalInfoRef?.current?.requestSubmit();
    },
  }));

  useEffect(() => {
    if (newSubject) {
      setValue("name", newSubject?.name);
      setValue("description", newSubject?.description);
      setValue("program_id", newSubject?.program_id);
      setValue("short_description", newSubject?.short_description);
      setValue("price", +newSubject?.price);
      setValue("offer_type", newSubject?.offer_type);
      setValue("offer_value", +newSubject?.offer_value);
      setImagePreview(newSubject?.thumb_image);
      if (newSubject?.features) {
        setFeatures([...newSubject?.features]);
      }
      clearErrors(["description", "name", "offer_value", "price"]);
    }
  }, [newSubject]);

  const onUploadThumbnail = (file: any) => {
    if (file) {
      var fileName = file.name,
        idxDot = fileName.lastIndexOf(".") + 1,
        extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        setThumbnail(file);
      } else {
        showNotification({
          message: "Only images of type jpg, jpeg, png are allowed!",
          theme: "coloured",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (thumbnail) {
      setImagePreview(URL.createObjectURL(thumbnail));
    }
  }, [thumbnail]);

  useEffect(() => {
    if (isUpdated) {
      navigate("/courses");
      setIsUpdated(false);
    }
  }, [isUpdated]);

  const onAddFeature = () => {
    if (featureRef?.current?.value !== "") {
      const data = featureRef.current.value;
      setFeatures((prev: any) => [...prev, data]);
      featureRef.current.value = "";
    } else {
      showNotification({
        message: "Enter a valid feature",
        theme: "coloured",
        type: "error",
      });
    }
  };

  const onRemoveFeatre = (idx: any) => {
    const prevOptions = [...features];
    const newOptions = prevOptions?.splice(idx, 1);
    setFeatures([...newOptions]);
  };

  const totalPriceTemplate = useCallback(() => {
    let price: any = +watch("price");
    let discount: any = watch("offer_value");
    let actualPrice: number = 0;
    if (watch("offer_type") === "percentage") {
      if (+discount >= 0) {
        actualPrice = price - (price * +discount) / 100;
      } else {
        actualPrice = price;
      }
    } else {
      if (+discount > 0 && +discount <= price) {
        actualPrice = price - +discount;
      } else {
        actualPrice = price;
      }
    }

    return (
      <div className="d-flex align-items-end gap-1">
        <h5 className="">${actualPrice}</h5>
        <p className="line-through disabled offer-text">${price}</p>
        {watch("offer_type") && watch("offer_type") === "percentage" && (
          <p className="text-primary offer-text">{discount}% off</p>
        )}
      </div>
    );
  }, [watch("price"), watch("offer_value"), watch("offer_type")]);

  const onSubmit = (data: any) => {
    if (data) {
      if (
        (watch("offer_type") === "percentage" || "amount") &&
        watch("offer_value") === 0
      ) {
        setError("offer_value", {
          type: "required",
          message: "Offer value is required",
        });
      } else {
        const price: any = watch("price");
        const offer_value: any = watch("offer_value");
        let formData = new FormData();
        formData.append("id", newSubject?.id);
        formData.append("name", watch("name"));
        formData.append("description", watch("description"));
        formData.append("price", price);
        formData.append("status", "active");
        formData.append("program_id", newSubject?.program_id);
        formData.append("short_description", watch("short_description"));
        formData.append("offer_type", watch("offer_type") || "");
        formData.append("offer_value", offer_value || "");
        if (thumbnail) {
          formData.append("thumb_image", thumbnail);
          data = { ...data, thumbnail: thumbnail };
        }
        if (features && features?.length) {
          features?.forEach((item: any, index: any) => {
            formData.append(`feature[${index}]`, item);
          });
        }
        dispatch(updateCourse(formData, null, setIsUpdated));
      }
    }
  };

  return (
    <form ref={additionalInfoRef} onSubmit={handleSubmit(onSubmit)}>
      <div className="row gap-4 gap-xl-0">
        <div className="col-xl-7">
          <div className="card h-100">
            <div className="card-title-wrapper">
              <h3 className="card-title">Additional Info</h3>

              <div className="line"></div>
            </div>
            <div className="content-det-section pb-4">
              <div className="content-img">
                <label
                  htmlFor="upload"
                  onChange={(e: any) => onUploadThumbnail(e?.target?.files[0])}
                >
                  <input type="file" id="upload" hidden accept="image/*" />
                  {imagePreview ? (
                    <div className="thumbnail-img-wrap">
                      <img
                        src={imagePreview}
                        className="thumbnail-img"
                        alt="thumbnail image"
                      />
                    </div>
                  ) : (
                    <div className="thumbnail-upload-card">
                      <span className="mb-2">
                        <PictureIcon />
                      </span>
                      <p className="hint">Click here to upload thumbnail</p>
                    </div>
                  )}
                </label>
              </div>
              <div className="content-sec">
                <h4 className="content-title">{watch("name")}</h4>
                {editDescription ? (
                  <div className="form-group mb-2 w-100">
                    <textarea
                      {...register("short_description")}
                      id="chapter_short_desc"
                      rows={5}
                      className={`form-group-textarea ${
                        errors?.short_description ? "error-border" : ""
                      }`}
                      placeholder="Description"
                      name="short_description"
                    ></textarea>
                    <p className="validation-text">
                      {" "}
                      {errors?.short_description?.message}{" "}
                    </p>
                    <button
                      type="button"
                      className="btn btn-sm text-danger me-2 btn-link p-0 text-decoration-none mr-2"
                      onClick={() => {
                        setValue(
                          "short_description",
                          newSubject?.short_description
                        );
                      }}
                    >
                      cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-link p-0 text-decoration-none"
                      onClick={() => setEditDescription(false)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <p className="content-desc">
                    {watch("short_description")}
                    <button
                      type="button"
                      className="btn btn-sm btn-link p-0 ms-3 text-decoration-none"
                      onClick={() => setEditDescription(true)}
                    >
                      Edit
                      <span className="ms-1 edit-square-icon">
                        <EditSquareIcon />
                      </span>
                    </button>
                  </p>
                )}
              </div>
            </div>

            <div className="form-group mb-3">
              <h5 className="card-title mb-1">What you will learn</h5>
              <h6 className="form-group-hint">
                What are the things that we expect from this course
              </h6>
              <div className="row mt-2">
                <div className="col-md-9">
                  <input
                    ref={featureRef}
                    type="text"
                    className="form-group-control mt-0"
                    placeholder="Feature bulletin"
                  />
                </div>
                <div className="col-md-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary h-100 add-feature-btn"
                    onClick={() => onAddFeature()}
                  >
                    <span className="me-1">
                      <PlusIcon />
                    </span>
                    Add
                  </button>
                </div>
              </div>
            </div>

            {features && features?.length ? (
              <ul className="feature-list">
                {features?.map((item: any, idx: any) => (
                  <li key={idx} className="d-flex align-items-center gap-3">
                    <p className="feature-text mb-0">{item}</p>
                    <button
                      type="button"
                      className="icon-button"
                      onClick={() => onRemoveFeatre(idx)}
                    >
                      <TrashIcon />
                    </button>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
        <div className="col-xl-5">
          <div className="card h-100">
            <div className="card-title-wrapper">
              <h3 className="card-title">Price & Discounts</h3>
              <div className="line"></div>
            </div>
            <div className="form-wrapper">
              <div className="form-group mb-4">
                <h5 className="form-group-label">Price</h5>
                <h6 className="form-group-hint">Actual course price</h6>
                <input
                  {...register("price")}
                  type="number"
                  className={`form-group-control ${
                    errors?.price ? "error-border" : ""
                  }`}
                  placeholder="Price "
                />
                <p className="validation-text"> {errors?.price?.message} </p>
              </div>

              <h5 className="subtitle mb-3">Discount</h5>

              <div className="d-flex align-items-center gap-4 mt-2 mb-4">
                <div className="form-radio-btn mb-0">
                  <input
                    type="radio"
                    {...register("offer_type")}
                    value="percentage"
                    id="radio1"
                  />
                  <label htmlFor="radio1">Percentage</label>
                </div>
                <div className="form-radio-btn mb-0">
                  <input
                    type="radio"
                    {...register("offer_type")}
                    value="amount"
                    id="radio2"
                  />
                  <label htmlFor="radio2">Value</label>
                </div>
              </div>

              <div className="form-group mb-4">
                <h6 className="form-group-hint">Discount</h6>
                <input
                  {...register("offer_value")}
                  type="number"
                  className={`form-group-control ${
                    errors?.offer_value ? "error-border" : ""
                  }`}
                  placeholder="Discount"
                  min={0}
                />
                <p className="validation-text">
                  {" "}
                  {errors?.offer_value?.message}{" "}
                </p>
              </div>
            </div>
            <div className="mt-auto">
              <div className="line"></div>
              <div className="d-flex justify-content-between align-items-center pt-3">
                <h5 className="">Price</h5>
                <div className="d-flex align-items-end gap-1">
                  {totalPriceTemplate()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

export default Feature;
