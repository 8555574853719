import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import "./students.scss";

// icons
import { ReactComponent as AddCirclePlusIcon } from "../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as GridPlusIcon } from "../../../assets/icons/icon-grid-plus.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/icon-sort.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/icon-category.svg";
import { ReactComponent as SearchNormalIcon } from "../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";

import { ReactComponent as DotsVerticalIcon } from "../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as UsersIcon } from "../../../assets/icons/icon-users.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/icon-edit.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/icon-trash.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/icon-copy.svg";

// images
import avatar from "../../../assets/images/avatar.png";
import { getStudentsList } from "../../../redux/pages/studentSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import Avatar from "react-avatar";


const Students = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>()
  
  const studentsList = useAppSelector((state) => state.student?.studentsList?.data);
  const [studentsListData, setStudentListData]= useState<any>([])
  const [serachInput, setSearchInput] =useState<any>("")
  const [filteredStudentList, setFilteredStudentList] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState("name"); 
  const [sortedStudentList, setSortedStudentList] = useState<any>([]);



  const filterStudents = (input: string) => {
    setSearchInput(input);
    const filteredList = studentsListData.filter((course: any) =>
      course?.name.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredStudentList(filteredList);
    setSearchInput(input)
  };


  useEffect(()=>{
    dispatch(getStudentsList())
  },[]);

 useEffect(()=>{
 if(studentsList){
  setStudentListData(studentsList)
 }
 },[studentsList]);

 const handleSortChange = (field: string) => {
  if (sortField === field) {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  } else {
    setSortField(field);
    setSortOrder("asc");
  }
};

 useEffect(() => {
  const sortedList = [...studentsListData];
  sortedList.sort((a: any, b: any) => {
    if (sortField === "name") {
      return sortOrder === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    } else if (sortField === "date") {
      return sortOrder === "asc" ? new Date(a.date).getTime() - new Date(b.date).getTime() : new Date(b.date).getTime() - new Date(a.date).getTime();
    }
    return 0;
  });
  setSortedStudentList(sortedList);
}, [studentsListData, sortField, sortOrder]);

  return (
    <>
      <div className="students-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between flex-wrap title-wrapper mx-4">
          <div>
            <h2 className="title">Students</h2>
            <p className="desc mb-2">Details of all students</p>
          </div>
        </div>

        {/* control section */}
        <div className="control-section-wrapper mx-4">
          <div className="d-flex justify-content-between flex-wrap gap-3  control-wrapper py-3">
            <div className="search-wrapper me-3">
              <div className="search-box">
                <a className="search-icon-button">
                  <SearchNormalIcon />
                </a>
                <input
                  type="text"
                  placeholder="Search course"
                  value={serachInput}
                  onChange={(e)=>filterStudents(e.target.value)}
                  className="search-form-control fw-bold"
                />
              </div>
            </div>

            <div className="d-flex gap-3">
              <div className="dropdown sort-dropdown">
                <button
                  className="control-btn dropdown-toggle"
                  // type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="me-2">
                    <SortIcon />
                  </span>
                  Sort
                </button>
                <ul className="dropdown-menu">
                  <li>
                      <a className={`dropdown-item ${
                          sortField === "name" ? "active" : ""
                        }`}
                        href="#"
                        onClick={() => handleSortChange("name")}>
                        Name
                      </a>
                    </li>
                    <li>
                      <a className={`dropdown-item ${
                          sortField === "date" ? "active" : ""
                        }`}
                        href="#"
                        onClick={() => handleSortChange("date")}>
                        Date
                      </a>
                    </li>
                  </ul>

               
              </div>
            </div>
          </div>
        </div>

        {/* students table */}
        <div className="table-wrapper students-table mx-4">
          <table className="table table-hover table-borderless table-responsive">
            <thead>
              <tr>
                <th scope="col">Student Name</th>
                <th scope="col">Email</th>
                <th scope="col">Date of Join</th>
                <th scope="col">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {(serachInput === "" ? sortedStudentList : filteredStudentList)?.length > 0 ? (
              (serachInput === "" ? sortedStudentList : filteredStudentList)?.map((item: any) => {
      
                  return (
                    // <Link to="/students/details">
                    <tr
                      role="button"
                      onClick={() => navigate("/students/details",{state:item.id})}
                      className="table-row"
                    >
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            {item?.profile_image ? <img src={item?.profile_image ?? avatar} alt="avatar" />
                            :  <Avatar  name={item?.name} size="32" textSizeRatio={1.5} round={true} /> }
                          </div>
                          <p className="text">{item.name}</p>
                        </div>
                      </td>
                      <td>
                        <p className="text">{item.email}</p>
                      </td>
                      <td>
                        <p className="text">{moment(item.created_at).format('YYYY-MM-DD')}</p>
                      </td>
                      <td>
                        {/* add active class for status active, and expired for expired status */}
                        <p className="status active">{item.status}</p>
                        {/* <p className="status expired">Expired</p> */}
                      </td>
                      <td>
                        <div className="dropdown more-dropdown">
                          <button
                            className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                            // type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <DotsVerticalIcon />
                          </button>

                          <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                              <button
                                // onClick={handleView}
                                className="dropdown-item"
                              >
                                <span className="me-2">
                                  <EditIcon />
                                </span>
                                Edit
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item text-danger"
                                // data-bs-toggle="modal"
                                // data-bs-target="#staticBackdrop"
                                // onClick={handleDelete}
                              >
                                <span className="me-2">
                                  <TrashIcon />
                                </span>
                                Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="empty-wrapper">
                  <td colSpan={5} className="mx-auto">
                    <div className="empty-container p-5">
                      <p className="description">No Students found</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* pagination section */}
        {/* <div className="d-flex align-items-center justify-content-center pt-3 pb-3">
          <nav aria-label="...">
            <ul className="pagination mb-0">
              <li className="page-item disabled me-4">
                <a className="page-link">
                  <span className="me-2">
                    <PaginationLeftArrowIcon />
                  </span>
                  Prev
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  1
                </a>
              </li>
              <li className="page-item active" aria-current="page">
                <a className="page-link">2</a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  3
                </a>
              </li>
              <li className="page-item disabled">
                <a className="page-link">
                  ..........
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  5
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  6
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  7
                </a>
              </li>
              <li className="page-item ms-4">
                <a className="page-link">
                  Next
                  <span className="ms-2">
                    <PaginationRightArrowIcon />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>
      {/* uncomment this component to see the delete modal */}
    </>
  );
};

export default Students;
