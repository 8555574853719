import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { showNotification } from "../Toaster/Toast";
import { useAppSelector } from "../../../redux/hooks";
import { setErrors as setUserErrors} from "../../../redux/pages/userSlice";
import { setErrors as setCategoryErrors} from "../../../redux/pages/categorySlice";
import { setErrors as setCourseErrors} from "../../../redux/pages/courseSlice";
import { setErrors as setOfferErrors} from "../../../redux/pages/offersSlice";
import { setErrors as setCouponErrors} from "../../../redux/pages/couponsSlice";


const ErrorHandler = () => {

  const dispatch = useDispatch<any>();
  const userErrorInfo = useAppSelector((state) => state.user?.errors);
  const categoryErrorInfo = useAppSelector((state) => state.programs.errors);
  const courseErrorInfo = useAppSelector((state) => state.course.errors);
  const offerErrorInfo = useAppSelector((state) => state.offer.errors);
  const couponErrorInfo = useAppSelector((state) => state.coupen.errors);


  const toConvertMessage = (data: any) => {
    let message = "";
    if (data?.errors) {
      message = data?.errors[Object.keys(data?.errors)[0]][0];
    } else {
      message = data?.message;
    }
    return message;
  };

  useEffect(() => {
    if (userErrorInfo) {
      showNotification({
        message: `${toConvertMessage(userErrorInfo?.data)}`,
        theme: "light",
        type: "error"
      });
      dispatch(setUserErrors(null));
    }
  }, [userErrorInfo]);

  useEffect(() => {
    if (categoryErrorInfo) {
      showNotification({
        message: `${toConvertMessage(categoryErrorInfo?.data)}`,
        theme: "light",
        type: "error"
      });
      dispatch(setCategoryErrors(null));
    }
  }, [categoryErrorInfo]);

  useEffect(() => {
    if (courseErrorInfo) {
      showNotification({
        message: `${toConvertMessage(courseErrorInfo?.data)}`,
        theme: "light",
        type: "error"
      });
      dispatch(setCourseErrors(null));
    }
  }, [courseErrorInfo]);

  useEffect(() => {
    if (offerErrorInfo) {
      showNotification({
        message: `${toConvertMessage(offerErrorInfo?.data)}`,
        theme: "light",
        type: "error"
      });
      dispatch(setOfferErrors(null));
    }
  }, [offerErrorInfo]);

  useEffect(() => {
    if (couponErrorInfo) {
      showNotification({
        message: `${toConvertMessage(couponErrorInfo?.data)}`,
        theme: "light",
        type: "error"
      });
      dispatch(setCouponErrors(null));
    }
  }, [couponErrorInfo]);

  return (<></>);
};

export default ErrorHandler;
