import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";

export interface userInterface {
  isLoading: boolean;
  errors: any;
  userList: any;
  permissionList: any;
  roleList: any;
  userRoleInfo: any;
  newRole: any;
  profileStore:any;
  profileDataUpdate:any;

}

const initialState: userInterface = {
  isLoading: false,
  errors: null,
  userList: [],
  permissionList: [],
  roleList: [],
  userRoleInfo: "",
  newRole: null,
  profileStore:[] ,
  profileDataUpdate:[],

}

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setLoading: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = payload;
    },
    setUserList: (state, { payload }: PayloadAction<any>) => {
      state.userList = payload;
    },
    setPermissionList: (state, { payload }: PayloadAction<any>) => {
      state.permissionList = payload
    },
    setRoleList: (state, { payload }: PayloadAction<any>) => {
      state.roleList = payload
    },
    setUserRoleInfo: (state, { payload }: PayloadAction<any>) => {
      state.userRoleInfo = payload
    },
    setNewRole: (state, { payload }: PayloadAction<any>) => {
      state.newRole = payload
    },
    setProfileStore: (state, { payload }: PayloadAction<any>) => {
      state.profileStore = payload;
    },
    setProfileImage: (state, { payload }: PayloadAction<any>) => {
      state.profileDataUpdate= payload;
   },
  }
})

export const getUserList = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/user/index"
    const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true })
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setUserList(response.data?.users))
        dispatch(setLoading(false))
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false))
    dispatch(setErrors(error?.response))
  }
}

export const updateUser = (data: any, setShowEditModal: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/user/update"
    const response = await axiosInstance({ data: { url: URL, method: "post", data: data }, token: true })
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false))
        setShowEditModal(false)
        dispatch(getUserList())
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false))
    dispatch(setErrors(error?.response))
  }
}

export const updateUserRole = (body: any, setShowModal: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/user/role/update"
    const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true })
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
        dispatch(getUserList());
        setShowModal(false);
        showNotification({
          message: "User role updated successfully",
          theme: "light",
          type: "success"
        });
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false))
    dispatch(setErrors(error?.response))
  }
}

export const deleteUser = (data: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/user/delete"
    let response = await axiosInstance({ data: { url: URL, method: "post", data: { id: data } }, token: true })
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false))
        dispatch(getUserList())
      }
    }
  } catch (error: any) {
    dispatch(setErrors(error?.response))
    dispatch(setLoading(false))
  }
}

export const createUser = (data: any, setShowCreateModal: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/user/store"
    const response = await axiosInstance({ data: { url: URL, method: "post", data: data }, token: true })
    if (response.data) {
      if (response.data.status && response.data.statu === "success") {
        dispatch(setLoading(false))
        dispatch(getUserList())
        setShowCreateModal(false)
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false))
    dispatch(setErrors(error?.response))
  }
}

export const userRoleDelete = (roleId: any, setShowDeleteModal: any, setSelectedRoleId: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/role/delete"
    const response = await axiosInstance({ data: { url: URL, method: "post", data: {id: roleId} }, token: true })
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
        setSelectedRoleId("");
        dispatch(getRoleList());
        setShowDeleteModal(false);
        showNotification({
          message: "User role deleted successfully",
          theme: "light",
          type: "success"
        });
      }
    }
  } catch (error: any) {
    dispatch(setErrors(error?.response))
    dispatch(setLoading(false))
  }
}

export const userRoleUpdate = (data: any, setShowUpdateRoleModal: any, setSelectedRoleId: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/role/update"
    const response = await axiosInstance({ data: { url: URL, data: data, method: "post" }, token: true })
    if (response.data) {
      if (response.data.status && (await response).data.status === "success") {
        dispatch(setLoading(false));
        setSelectedRoleId("");
        dispatch(getRoleList());
        setShowUpdateRoleModal(false);
        showNotification({
          message: "User role updated successfully",
          theme: "light",
          type: "success"
        });
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false))
    dispatch(setErrors(error?.response))
  }
}

export const getUserRoleInfo = (roleId: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/role/index"
    const response = await axiosInstance({ data: { url: URL, method: "post", data: {id: roleId} }, token: true })
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false))
        dispatch(setUserRoleInfo(response.data))
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false))
    dispatch(setErrors(error?.response))
  }
}

export const getRoleList = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/role/list"
    const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true })
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false))
        dispatch(setRoleList(response.data?.role))
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false))
    dispatch(setErrors(error?.response))
  }
}

export const createRole = (data: any, setShowModal: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/role/store"
    const response = await axiosInstance({ data: { url: URL, method: "post", data: data }, token: true })
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
        // dispatch(setNewRole(response.data?.role));
        // uncomment when response updated
        dispatch(getRoleList());
        setShowModal(false);
        showNotification({
          message: "User role added successfully",
          theme: "light",
          type: "success"
        });
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false))
    dispatch(setErrors(error?.response))
  }
}

export const getPermissionList = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    let URL = "/permission/index"
    const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true })
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false))
        dispatch(setPermissionList(response.data?.permissions))
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false))
    dispatch(setErrors(error?.response))
  }
}

export const getProfileData = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
     let URL = "/profile/index";
     const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
     if (response.data) {
      console.log(response?.data,"profile dat")
        if (response.data.status && response.data.status === "success") {
          dispatch( setProfileStore(response.data));
          dispatch(setLoading(false));    
        }
     }
  } catch (error: any) {
     dispatch(setLoading(false));
     dispatch(setErrors(error?.response));
  }
};

export const profileDataUpdate = (body: {image:any}): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
     let URL = "/profile/image/store";
     const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
     if (response.data) {
        if (response.data.status && response.data.status === "success") {
           dispatch(setLoading(false));
           showNotification({
              message: "Profile data updated.",
              theme: "light",
              type: "success",
           });
           dispatch( setProfileImage(body));
        }
     }
  } catch (error: any) {
     dispatch(setLoading(false));
     dispatch(setErrors(error?.response));
  }
};

export const {
  setLoading,
  setErrors,
  setUserList,
  setPermissionList,
  setRoleList,
  setUserRoleInfo,
  setNewRole,
  setProfileStore,
  setProfileImage ,
} = userSlice.actions

export default userSlice.reducer;
