import React, { useEffect, useState } from "react";
import "./CourseCard.scss";

import { useNavigate } from "react-router-dom";

import { ReactComponent as DotsVerticalIcon } from "../../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as UsersIcon } from "../../../../assets/icons/icon-users.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/icon-edit.svg";
import { ReactComponent as MenuStarIcon } from "../../../../assets/icons/icon-menu-star.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/icon-trash.svg";

import avatar from "../../../../assets/images/avatar.png";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { deleteCourse, getCourseDetails, setNewSubjectInfo, setSubjectDetails } from "../../../../redux/pages/courseSlice";
import { useAppSelector } from "../../../../redux/hooks";
import DeleteModal from "../../../../common/components/Modal/DeleteModal/DeleteModal";
import CourseDetails from "../../CourseDetails/CourseDetails";

interface CourseCardProps {
  course: any;
}

const CourseCard: React.FC<CourseCardProps> = (props) => {
  const {
    course = {},
  } = props;

  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [courseId, setCourseId] = useState<any>(null);
  const [isNavigate, setIsNavigate] = useState<boolean>(false);
  const [studentsList, setStudentList] = useState<any>([]);
  const dispatch = useDispatch<any>();
  const subjectDetails = useAppSelector((state) => state.course.subjectDetails);

  useEffect(() => {
    if (subjectDetails && isNavigate) {
      dispatch(setNewSubjectInfo(subjectDetails));
      dispatch(setSubjectDetails(""));
      navigate("/courses/manage_course");
      setIsNavigate(false);
    }
  }, [isNavigate]);

  const handleViewCourse = (course: any) => {
    navigate("/courses/course_details", { state: { courseData: course } });
  };

  const handleDelete = (course: any) => {
    if(course && course?.id) {
      setCourseId(course?.id);
      setShowDeleteModal(true);
    }
  };

  const handleEditBtn = (item: any) => {
    if(item && item?.id) {
      dispatch(getCourseDetails(item?.id, setIsNavigate));
    }
  };

  const onConfirmDelete = () => {
    if (courseId) {
      const data = { id: courseId }
      dispatch(deleteCourse(data, setShowDeleteModal, setCourseId));
    }
  };

  const onCancelDeleteAction = () => {
    setCourseId(null);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = format(date, "dd MMMM yyyy");
    return formattedDate;
  };
  useEffect(()=>{
  if(course){
    setStudentList(course?.students)
  }
  },[course])

  return (
    <>
      <div className="course-card">
        <div className="course-img">
          <img
            src={course?.thumb_image}
            alt="course image"
          />
        </div>
        <div className="course-card-body pt-2">
          <div className="d-flex flex-column justify-content-between card-content-wrap">
            {/* card title section */}
            <div className="d-flex justify-content-between">
              <div className="flex-1">
                <h4 className="course-card-title">{course?.name}</h4>
                <p className="course-det">
                  Created by Mathew A | {formatDate(course?.created_at)}
                </p>
              </div>
              <div className="dropdown more-dropdown">
                <button
                  className="dropdown-toggle more-dropdown-btn"
                  // type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <DotsVerticalIcon />
                </button>

                <ul className="dropdown-menu">
                  <li>
                    <button onClick={() => handleEditBtn(course)} className="dropdown-item">
                      <span className="me-2">
                        <EditIcon />
                      </span>
                      Edit
                    </button>
                  </li>

                  {/* <li>
                    <a className="dropdown-item">
                      <span className="me-2">
                        <MenuStarIcon />
                      </span>
                      Add Offer
                    </a>
                  </li> */}
                  <li>
                    <button className="dropdown-item text-danger" onClick={() => handleDelete(course)}>
                      <span className="me-2">
                        <TrashIcon />
                      </span>
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {/* card bottom section */}
            <div className="pt-3">
              <div className="d-flex align-items-center gap-2">
                <span className="">
                  <UsersIcon />
                </span>
                <div className="dropdown students-dropdown">
                  <button
                    className="dropdown-toggle students-dropdown-btn"
                    // type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                   {course?.students.length}
                  </button>

                   
                  <ul className="dropdown-menu student-dropdown-menu">
                  {studentsList?.map((student:any, key:any)=>(  
                    <li key={key}>
                      <a className="dropdown-item">
                        <span className="me-2">
                          <img
                            src={student?.profile_image}
                            className="student-image"
                            // alt="student image"
                          />
                        </span>
                         {student?.name}
                      </a>
                    </li>
                    ))}
                    </ul>
                    
                </div>
                <p className="det-text">enrolled in this course</p>
              </div>
              <div className="view-det-btn mt-2">
                <button
                  onClick={() => handleViewCourse(course)}
                  className="btn btn-sm btn-outline-primary w-100"
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onConfirmDelete}
          onCancel={onCancelDeleteAction}
          message={`The course ${course?.name} will be permanently deleted.`}
        />)}
    </>
  );
};

export default CourseCard;
