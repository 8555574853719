import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";

export interface chatInterface {
  isLoading: boolean;
  errors: any;
  getChatList:any;
}

const initialState: chatInterface = {
  isLoading: false,
  errors: null,
  getChatList:[] ,
}

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setLoading: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = payload;
    },
    setChatList: (state, { payload }: PayloadAction<any>) => {
      state.getChatList = payload;
    },
  }
})

export const chatStore = (body: {student_id:any, subject_id:any,message:any}): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
       let URL = "/chat/store";
       const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
       if (response.data) {
          if (response.data.status && response.data.status === "success") {
             dispatch(setLoading(false));
          }
       }
    } catch (error: any) {
       dispatch(setLoading(false));
       dispatch(setErrors(error?.response));
    }
  };
  

export const chatList= (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
     let URL = "/chat/list";
     const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
     if (response.data) {
        if (response.data.status && response.data.status === "success") {
          dispatch( setChatList(response.data));
          dispatch(setLoading(false));    
        }
     }
  } catch (error: any) {
     dispatch(setLoading(false));
     dispatch(setErrors(error?.response));
  }
};


export const chatRead = (body: {student_id:any, subject_id:any}): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
     let URL = "/chat/read";
     const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
     if (response.data) {
        if (response.data.status && response.data.status === "success") {
           dispatch(setLoading(false));
        }
     }
  } catch (error: any) {
     dispatch(setLoading(false));
     dispatch(setErrors(error?.response));
  }
};


export const {
  setLoading,
  setErrors,
  setChatList,
} = chatSlice.actions

export default chatSlice.reducer;
