import React from "react";
import "./DeleteModal.scss";

import { ReactComponent as TrashIcon } from "../../../../assets/icons/icon-trash.svg";
import { ReactComponent as CircleCloseIcon } from "../../../../assets/icons/icon-close-circle.svg";

interface DeleteProps {
  message?: any;
  showModal: (value: boolean) => void;
  onConfirm: (value: any) => void;
  onCancel?: () => void;
}

const DeleteModal: React.FC<DeleteProps> = (props) => {
  const {
    message = "All data will be permanently deleted.",
    showModal = () => {},
    onConfirm = () => {},
    onCancel = () => {},
  } = props;

  return (
    <div
      className="as-modal delete-modal"
      id="staticBackdrop"
      aria-hidden="true"
      onClick={() => showModal(false)}
    >
      <div
        className="modal-dialog-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header mb-3">
              <span className="trash-circle-icon">
                <TrashIcon />
              </span>
              <button
                className="close-modal-btn"
                onClick={() => {
                  showModal(false);
                  onCancel();
                }}
              >
                <CircleCloseIcon />
              </button>
            </div>
            <div className="modal-body mb-3">
              <h4 className="modal-title">Are you sure you want to delete?</h4>
              <p className="modal-desc">
                {/* All lectures, tests and notes with this subject will be
                permanently deleted. */}
                {message}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-danger"
                // data-bs-dismiss="modal"
                onClick={onConfirm}
              >
                <span className="me-2">
                  <TrashIcon />
                </span>
                Delete
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-black close-modal-btn"
                onClick={() => {
                  showModal(false);
                  onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
